import repository from '@/repository'
import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): SchedulesState => {
  return {
    schedulesList: [],
    schedulesListIsLoading: false,
    schedulesListErrorMessage: '',
    nonStandardDatesList: [],
    nonStandardDatesListIsLoading: false,
    nonStandardDatesListErrorMessage: '',
    saveScheduleIsLoading: false,
    saveScheduleErrorMessage: '',
    saveScheduleValidationError: false,
    saveDateIsLoading: false,
    saveDateErrorMessage: '',
    enableDisableScheduleIsLoading: false,
    enableDisableScheduleError: false,
    enableDisableScheduleErrorMessage: '',
    alertSchedulesList: [],
    alertSechulesListIsLoading: false,
  }
}

const state = getDefaultState()

const mutations: MutationTree<SchedulesState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setSchedulesList(state, value) {
    state.schedulesList = value
  },
  setSchedulesListIsLoading(state, value) {
    state.schedulesListIsLoading = value
  },
  setSchedulesListErrorMessage(state, value) {
    state.schedulesListErrorMessage = value
  },
  setNonStandardDatesList(state, value) {
    state.nonStandardDatesList = value
  },
  setNonStandardDatesListIsLoading(state, value) {
    state.nonStandardDatesListIsLoading = value
  },
  setNonStandardDatesListErrorMessage(state, value) {
    state.nonStandardDatesListErrorMessage = value
  },
  setSaveScheduleIsLoading(state, value) {
    state.saveScheduleIsLoading = value
  },
  setSaveScheduleErrorMessage(state, value) {
    state.saveScheduleErrorMessage = value
  },
  setSaveScheduleValidationError(state, value) {
    state.saveScheduleValidationError = value
  },
  setSaveDateIsLoading(state, value) {
    state.saveDateIsLoading = value
  },
  setSaveDateErrorMessage(state, value) {
    state.saveDateErrorMessage = value
  },
  updateScheduleList(state, value) {
    const currentScheduleIndex = state.schedulesList.findIndex((schedule) => {
      if (schedule.scheduleId == value.scheduleId) {
        return schedule
      }
    })
    if (currentScheduleIndex != -1) {
      state.schedulesList[currentScheduleIndex] = value
    } else {
      state.schedulesList.push(value)
    }
  },
  updateNonStandardDatesList(state, value) {
    const currentDateIndex = state.nonStandardDatesList.findIndex((date) => {
      if (date.definitionId == value.definitionId) {
        return date
      }
    })
    if (currentDateIndex != -1) {
      state.nonStandardDatesList[currentDateIndex] = value
    } else {
      state.nonStandardDatesList.push(value)
    }
  },
  setEnableDisableSchedule(state, value) {
    state.schedulesList.forEach((schedule) => {
      if (schedule.scheduleId == value.scheduleId) {
        schedule.disabled = value.disabled
      }
    })
  },
  setEnableDisableScheduleIsLoading(state, value) {
    state.enableDisableScheduleIsLoading = value
  },
  setEnableDisableScheduleError(state, value) {
    state.enableDisableScheduleError = value
  },
  setEnableDisableScheduleErrorMessage(state, value) {
    state.enableDisableScheduleErrorMessage = value
  },
  setAlertSchedulesList(state, value) {
    state.alertSchedulesList = value
  },
  setAlertSchedulesListIsLoading(state, value) {
    state.alertSechulesListIsLoading = value
  },
}

const actions: ActionTree<SchedulesState, RootState> = {
  async getSchedulesList({ commit }, payload) {
    commit('setSchedulesListIsLoading', true)
    return repository.Schedule.Schedules(payload)
      .then((res) => {
        commit('setSchedulesList', res.data)
      })
      .catch((err) => {
        commit(
          'setSchedulesListErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setSchedulesListIsLoading', false)
      })
  },
  async getNonStandardDatesList({ commit }, payload) {
    commit('setNonStandardDatesListIsLoading', true)
    return repository.Schedule.NonStandardScheduleDefinitions(payload)
      .then((res) => {
        commit('setNonStandardDatesList', res.data)
      })
      .catch((err) => {
        commit(
          'setNonStandardDatesListErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setNonStandardDatesListIsLoading', false)
      })
  },
  async saveSchedule({ commit }, payload) {
    commit('setSaveScheduleIsLoading', true)
    commit('setSaveScheduleValidationError', false)
    return repository.Schedule.UpdateSchedule(payload)
      .then(() => {
        commit('updateScheduleList', payload)
        commit('setSaveScheduleErrorMessage', '')
      })
      .catch((err) => {
        if (err.response.status == 400) {
          commit('setSaveScheduleValidationError', true)
          commit(
            'setSaveScheduleErrorMessage',
            err.response.data.messageLocalizable
          )
        } else {
          commit('setSaveScheduleValidationError', false)
          commit(
            'setSaveScheduleErrorMessage',
            err.response.data.messageLocalizable
          )
        }
      })
      .finally(() => {
        commit('setSaveScheduleIsLoading', false)
      })
  },
  async saveDate({ commit }, payload) {
    commit('setSaveDateIsLoading', true)
    return repository.Schedule.UpdateNonStandardScheduleDefinition(payload)
      .then((res) => {
        payload.definitionId = res.data
        commit('updateNonStandardDatesList', payload)
        commit('setSaveDateErrorMessage', '')
      })
      .catch((err) => {
        commit('setSaveDateErrorMessage', err.response.data.messageLocalizable)
      })
      .finally(() => {
        commit('setSaveDateIsLoading', false)
      })
  },
  async removeDate({ commit }, payload) {
    commit('setSaveDateIsLoading', true)
    return repository.Schedule.UpdateNonStandardScheduleDefinition(payload)
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        commit('setSaveDateErrorMessage', err.response.data.messageLocalizable)
      })
      .finally(() => {
        commit('setSaveDateIsLoading', false)
      })
  },
  async enableDisableSchedule({ commit }, payload) {
    commit('setEnableDisableScheduleIsLoading', true)
    return repository.Schedule.UpdateScheduleStatus(payload)
      .then(() => {
        commit('setEnableDisableSchedule', payload)
      })
      .catch((err) => {
        commit('setEnableDisableScheduleError', true)
        commit(
          'setEnableDisableScheduleErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setEnableDisableScheduleIsLoading', false)
      })
  },
  clearEnableDisableScheduleError({ commit }) {
    commit('setEnableDisableScheduleError', false)
    commit('setEnableDisableScheduleErrorMessage', '')
  },
  getAlertSchedulesList({ commit }) {
    commit('setAlertSchedulesListIsLoading', true)
    const list = [
      { scheduleId: 1, disabled: false, name: 'test alert schedule' },
    ]
    commit('setAlertSchedulesList', list)
    setTimeout(() => {
      commit('setAlertSchedulesListIsLoading', false)
    }, 1000)
  },
}

const Schedules: Module<SchedulesState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default Schedules

interface SchedulesState {
  schedulesList: Schedule[]
  schedulesListIsLoading: boolean
  schedulesListErrorMessage: string
  nonStandardDatesList: NonStandardDate[]
  nonStandardDatesListIsLoading: boolean
  nonStandardDatesListErrorMessage: string
  saveScheduleIsLoading: boolean
  saveScheduleErrorMessage: string
  saveScheduleValidationError: boolean
  saveDateIsLoading: boolean
  saveDateErrorMessage: string
  enableDisableScheduleIsLoading: boolean
  enableDisableScheduleError: boolean
  enableDisableScheduleErrorMessage: string
  alertSchedulesList: Schedule[]
  alertSechulesListIsLoading: boolean
}

interface Schedule {
  scheduleId: number
  disabled: boolean
}

interface NonStandardDate {
  definitionId: number
}
