import repository from '@/repository'

export default {
  data() {
    return {
      device: {},
      deviceLoading: false,
      restoreDeviceSettingError: false,
      currentVideoWidth: 0,
      currentVideoHeight: 0,
      singleVCAEvent: {},
      vcaEvents: [],
      cameraApiError: false,
      cameraApiErrorMessage: '',
      deviceStartPlaybackLoading: false,
      analyticsIsLoading: false,
      isAdjustingSettings: false,
      currentClip: [],
    }
  },
  methods: {
    async updatePlaybackSource(payload) {
      return repository.Device.UpdatePlaybackSource(payload).then((res) => {
        if (res.data.item) {
          this.device = {
            ...this.device,
            playbackSource: res.data.item.playbackSource,
            changedBy: res.data.item.changedBy,
            sdOldestFootage: res.data.item.sdOldestFootage,
            sdNewestFootage: res.data.item.sdNewestFootage,
          }
        }
      })
    },
    async updateCameraName(payload) {
      this.device.deviceName = payload
    },
    async bookmarkDevice(payload) {
      return repository.Device.BookMarkDevice(payload).then(() => {
        this.device.isBookMarked = payload.isBookMarked
      })
    },
    async getVCAEvents(payload) {
      this.analyticsIsLoading = true
      return repository.Device.GetVCAEvents(payload)
        .then((res) => {
          this.vcaEvents = res.data.items.sort((a, b) => {
            return new Date(a.eventCreatedOn) - new Date(b.eventCreatedOn)
          })
        })
        .finally(() => {
          this.analyticsIsLoading = false
        })
    },
    async deviceStartPlayback(payload) {
      this.cameraApiError = false
      return repository.Device.DeviceStartPlayback(
        payload.parm,
        payload.config
      ).catch(() => {
        this.cameraApiError = true
        this.cameraApiErrorMessage = this.$t('dealer.cameraVideoPlayError')
      })
    },
    async devicePausePlayback(payload) {
      this.cameraApiError = false
      return repository.Device.DevicePausePlayback(
        payload.parm,
        payload.config
      ).catch(() => {
        this.cameraApiError = true
        this.cameraApiErrorMessage = this.$t('dealer.cameraVideoPlayError')
      })
    },
    setAdjustingSettingsStatus(payload) {
      this.isAdjustingSettings = payload
    },
    async getDeviceByID(payload) {
      this.deviceLoading = true
      return repository.Device.GetDeviceByID(payload)
        .then((res) => {
          this.device = res.data
        })
        .finally(() => {
          this.deviceLoading = false
        })
    },
    async getDevicePlayback(payload) {
      return repository.Device.GetDevicePlayback(payload).then((res) => {
        if (res.data.deviceStatus == '') {
          res.data.deviceStatus = payload.deviceStatus
        }
        this.device = {
          ...res.data,
          vcaRules: this.device.vcaRules,
          userPermissions: this.device.userPermissions,
        }
      })
    },
    async deviceWebRTCRestart(payload) {
      return repository.Device.DeviceWebRTCRestart(payload)
    },
    async getWebRTCPeerCount(payload) {
      return repository.Device.GetWebRTCPeerCount(payload)
    },
    async deviceStartSmartSearchPlayback(payload) {
      this.deviceStartPlaybackLoading = true
      this.cameraApiError = false
      return repository.Device.DeviceStartSmartSearchPlayback(
        payload.parm,
        payload.config
      )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch(() => {
          this.cameraApiError = true
          this.cameraApiErrorMessage = this.$t('dealer.cameraVideoPlayError')
        })
        .finally(() => {
          this.deviceStartPlaybackLoading = false
        })
    },
    async getSingleVCAEvent(payload) {
      repository.Device.GetSingleVCAEvent(payload).then((res) => {
        this.singleVCAEvent = res.data
      })
    },
    async getCurrentClip(payload) {
      this.currentClipLoading = true

      return repository.Device.GetDeviceVideoOnDemandSegment(payload)
        .then((res) => {
          this.currentClip = res.data
          if (res.data.isDstReplay) {
            this.device.isDstReplay = res.data.isDstReplay
          }
        })
        .finally(() => {
          this.currentClipLoading = false
        })
    },
    async deviceStopPlayback(payload) {
      this.cameraApiError = false
      return repository.Device.DeviceStopPlayback(
        payload.parm,
        payload.config
      ).catch(() => {
        this.cameraApiError = true
        this.cameraApiErrorMessage = this.$t('dealer.cameraVideoPlayError')
      })
    },
    updateDevice(payload) {
      this.device = payload
    },
  },
}
