
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
export default defineComponent({
  name: 'ChatHistoryList',
  components: {},
  props: {
    groupedChatHistory: {
      type: Array,
      default: () => [] as any[],
      required: true,
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedEntry: null,
      isEdit: false,
      currentTitle: '',
    }
  },
  computed: {
    titleDisabled() {
      return this.currentTitle.trim().length === 0
    },
  },
  methods: {
    ...mapActions('Chat', ['updateCurrentChat']),
    handleSelectHistory(entry: any) {
      this.updateCurrentChat(entry)
      this.$emit('selectHistory', entry)
    },
    handleDeleteHistory(entry: any) {
      this.isEdit = false
      this.selectedEntry = entry.id
      this.$emit('deleteHistory', entry)
    },
    onEdit(entry: any) {
      this.selectedEntry = entry.id
      this.isEdit = true
    },
    editedCancel() {
      this.selectedEntry = null
      this.isEdit = false
    },
    editedUpdate() {
      if (this.titleDisabled) {
        return
      }
      this.isEdit = false
      this.$emit('editHistory', {
        id: this.selectedEntry,
        title: this.currentTitle,
      })
      this.selectedEntry = null
    },
    handleEditHistory(entry: any) {
      this.currentTitle = entry.title
      this.selectedEntry = entry.id
      this.isEdit = true
    },
    formatMonth(month: string) {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()

      const [monthName, yearString] = month.split(' ')
      const year = parseInt(yearString)

      if (year === currentYear) {
        return monthName
      } else {
        return month
      }
    },
    loadMoreEntries() {
      this.$emit('loadMoreHistory')
    },
  },
  mounted() {
    const observerTarget = this.$refs.observerTarget
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.loadMoreEntries()
        }
      })
    }, options)

    if (observerTarget) {
      observer.observe(observerTarget)
    }
  },
})
