export default [
  {
    path: '/shared-cases',
    name: 'SharedCases',
    component: () =>
      import(
        /*webpackChunkName: "SharedCases" */ '@/views/sharedCases/LandingPage.vue'
      ),
    props: true,
    meta: {
      title: 'Shared Cases',
      allowAnonymous: true,
      customerOnly: false,
      showHeader: true,
      showFooter: true,
      externalOnly: true,
      internalOnly: false,
    },
  },
  {
    path: '/shared-cases/:id',
    name: 'SharedCase',
    component: () =>
      import(/*webpackChunkName: "SharedCases" */ '@/views/case/Case.vue'),
    props: true,
    meta: {
      title: 'Case',
      allowAnonymous: true,
      customerOnly: false,
      showHeader: true,
      showFooter: true,
      externalOnly: true,
      internalOnly: false,
    },
  },
  {
    path: '/vigil-cloud-camera/:id',
    name: 'VigilCloudCamera',
    component: () =>
      import(
        /*webpackChunkName: "VigilCloudCamera" */ '@/views/singleCamera/SingleCamera.vue'
      ),
    props: true,
    meta: {
      title: 'Camera',
      allowAnonymous: true,
      needAuthenticate: true,
      showHeader: false,
      showFooter: false,
      dealerAndCustomer: true,
    },
  },
  {
    path: '/vigil-cloud-cases',
    name: 'VigilCloudCases',
    component: () =>
      import(
        /*webpackChunkName: "VigilCloudCases" */ '@/views/library/Library.vue'
      ),
    props: true,
    meta: {
      title: 'Cases',
      allowAnonymous: true,
      needAuthenticate: true,
      showHeader: false,
      showFooter: false,
      dealerAndCustomer: true,
    },
  },
  {
    path: '/vigil-cloud-case/:id',
    name: 'VigilCloudCase',
    component: () =>
      import(/*webpackChunkName: "VigilCloudCase" */ '@/views/case/Case.vue'),
    props: true,
    meta: {
      title: 'Case',
      allowAnonymous: true,
      needAuthenticate: true,
      showHeader: false,
      showFooter: false,
      dealerAndCustomer: true,
    },
  },
]
