import {
  Permission,
  RolePermission,
  User,
  UserAllLocation,
  UserAssignedLocation,
} from '@/models'
import repository from '@/repository'
import i18n from '../../i18n'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): VigilUserDetailState => {
  return {
    userEditIsLoading: false,
    userEditError: false,
    userEditErrorMessage: '',
    userDetailObject: {},
    userDetailObjectIsLoading: false,
    userDeleteIsLoading: false,
    userDeleteErrorMessage: '',
    userDeleteError: false,
    userEnableDisableError: false,
    userEnableDisableErrorMessage: '',
    rolePermissionsList: [],
    userAssignedLocations: [],
    userAllLocations: [],
    userAssignedToAllLocations: false,
    updateUserInfoError: false,
    updateUserInfoErrorMessage: '',
  }
}

const state = getDefaultState()

const getters: GetterTree<VigilUserDetailState, RootState> = {
  userAssignedLocations: (state) => {
    const list = state.userAssignedLocations.map(
      (item: UserAssignedLocation) => {
        if (item.streetAddress.includes('|')) {
          const updatedString = item.streetAddress.replaceAll('|', ' ')
          return { ...item, streetAddress: updatedString }
        }
        return item
      }
    )
    return list
  },
  userAllLocations: (state) => {
    const list = state.userAllLocations.map((item: UserAllLocation) => {
      if (item.streetAddress.includes('|')) {
        const updatedString = item.streetAddress.replaceAll('|', ' ')
        return { ...item, streetAddress: updatedString }
      }
      return item
    })
    return list
  },
}

const mutations: MutationTree<VigilUserDetailState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setUserEditError(state, value) {
    state.userEditError = value
  },
  setUserEditIsLoading(state, value) {
    state.userEditIsLoading = value
  },
  setUserEditErrorMessage(state, value) {
    state.userEditErrorMessage = value
  },
  setUserDetailObject(state, value) {
    state.userDetailObject = value
  },
  setUserDetailObjectIsLoading(state, value) {
    state.userDetailObjectIsLoading = value
  },
  setUserEnableDisableError(state, value) {
    state.userEnableDisableError = value
  },
  setUserEnableDisableErrorMessage(state, value) {
    state.userEnableDisableErrorMessage = value
  },
  setUpdateUserDetailEnableDisable(state, value) {
    state.userDetailObject.isActive = !value.isActive
  },
  setRolePermissionList(state, value) {
    state.rolePermissionsList = value
  },
  setUserAssignedLocations(state, value) {
    state.userAssignedLocations = value
  },
  setUserAssignedToAllLocations(state, value) {
    state.userAssignedToAllLocations = value
  },
  setUserAllLocations(state, value) {
    state.userAllLocations = value
  },
  updateAssignedLocation(state, value) {
    const locationID = value.locationId
    const currentLocation = state.userAllLocations.find(
      ({ locationId }) => locationId === locationID
    )
    if (typeof currentLocation !== 'undefined')
      currentLocation.assignedToUser = !value.assignedToUser
  },
  setUpdateUserInfoError(state, value) {
    state.updateUserInfoError = value
  },
  setUpdateUserInfoErrorMessage(state, value) {
    state.updateUserInfoErrorMessage = value
  },
  setUpdateUserDetailObject(state, value) {
    state.userDetailObject.firstName = value.FirstName
    state.userDetailObject.lastName = value.LastName
    state.userDetailObject.emailAddress = value.EmailAddress
    state.userDetailObject.phone = value.Phone
  },
  setUserDeleteError(state, value) {
    state.userDeleteError = value
  },
  setUserDeleteErrorMessage(state, value) {
    state.userDeleteErrorMessage = value
  },
  setPrimaryContact(state) {
    state.userDetailObject.isPrimaryContact = true
  },
}

const actions: ActionTree<VigilUserDetailState, RootState> = {
  async editUser({ commit }, payload) {
    commit('setUserEditIsLoading', true)
    commit('setUserEditError', false)
    return repository.User.Update(payload)
      .then(() => {
        commit('setUpdateUserDetailObject', payload)
      })
      .catch((err) => {
        commit('setUserEditError', true)
        commit('setUserEditErrorMessage', err.response.data.messageLocalizable)
      })
      .finally(() => {
        commit('setUserEditIsLoading', false)
      })
  },
  clearEditUserError({ commit }) {
    commit('setUserEditErrorMessage', '')
    commit('setUserEditError', false)
    commit('setUserDeleteError', false)
    commit('setUserDeleteErrorMessage', '')
    commit('setUpdateUserInfoError', false)
    commit('setUpdateUserInfoErrorMessage', '')
  },
  async setCustomerUserPrimary({ commit }, payload) {
    commit('setUserDetailObjectIsLoading', true)
    return repository.Customer.SetPrimary(payload)
      .then(() => {
        commit('setPrimaryContact')
      })
      .catch(() => {})
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },
  async setDealerUserPrimary({ commit }, payload) {
    commit('setUserDetailObjectIsLoading', true)
    return repository.Dealer.SetPrimary(payload)
      .then(() => {
        commit('setPrimaryContact')
      })
      .catch(() => {})
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },
  async getCustomerUserDetail({ commit }, payload) {
    // this action loads all data for customer user detail view
    commit('setUserDetailObject', {})
    commit('setUserDetailObjectIsLoading', true)
    await repository.Location.GetAssignedUserLocations(payload)
      .then((res) => {
        const assignedLocations = []
        res.data.locations.forEach((location: UserAssignedLocation) => {
          if (location.assignedToUser) {
            assignedLocations.push(location)
          }
        })
        commit('setUserAssignedLocations', assignedLocations)
        commit('setUserAssignedToAllLocations', res.data.assignToAllLocations)
        commit('setUserAllLocations', res.data.locations)
      })
      .catch(() => {
        commit('setUserAssignedLocations', [])
        commit('setUserAssignedToAllLocations', false)
        commit('setUserAllLocations', [])
      })
    await repository.Role.GetRolesPermissions({ UserType: 'Customer' })
      .then((res) => {
        const roleListData = res.data.items
        roleListData.forEach((role: RolePermission) => {
          role.roleType = (i18n.t('global.default') as string) || 'default'
          let numberOfPermissionsAssigned = 0
          role.permissions.forEach((permission: Permission) => {
            if (permission.isAvailable) {
              numberOfPermissionsAssigned += 1
            }
          })
          role.numberOfPermissionsAssigned = numberOfPermissionsAssigned
          role.numberOfPeopleAssigned = role.users.length
          role.users.forEach((user) => {
            if (user.isActive) {
              user.status = 'Enabled'
            } else {
              user.status = 'Disabled'
            }
          })
        })
        commit('setRolePermissionList', res.data.items)
      })
      .catch((err) => {
        commit('setGetRolesError', err.response.data.messageLocalizable)
      })

    return repository.Customer.GetUsers(payload)
      .then((res) => {
        const currentUser = res.data.items.find((user: User) => {
          return user.userId === payload.UserId
        })
        commit('setUserDetailObject', currentUser)
      })
      .catch(() => {})
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },
  async getSuperAdminUserDetail({ commit }, payload) {
    commit('setUserDetailObject', {})
    commit('setUserDetailObjectIsLoading', true)
    await repository.Role.GetRolesPermissions({ UserType: 'Internal' })
      .then((res) => {
        commit('setRolePermissionList', res.data.items)
      })
      .catch((err) => {
        commit('setGetRolesError', err.response.data.messageLocalizable)
      })

    return repository.InternalAdmin.GetUsers(payload)
      .then((res) => {
        const currentUser = res.data.items.find((user: User) => {
          return user.userId === payload.UserId
        })
        commit('setUserDetailObject', currentUser)
      })
      .catch(() => {})
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },
  async updateSuperAdminUserRole({ commit }, payload) {
    commit('setUserDetailObjectIsLoading', true)
    commit('setUpdateUserInfoError', false)
    await repository.User.Update(payload)
      .then(() => {})
      .catch((err) => {
        commit('setUpdateUserInfoError', true)
        commit(
          'setUpdateUserInfoErrorMessage',
          err.response.data.messageLocalizable
        )
      })
    return repository.InternalAdmin.GetUsers(payload)
      .then((res) => {
        const currentUser = res.data.items.find((user: User) => {
          return user.userId === payload.UserId
        })
        commit('setUserDetailObject', currentUser)
      })
      .catch(() => {})
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },
  async getSuperAdminDealerUserDetail({ commit }, payload) {
    commit('setUserDetailObject', {})
    commit('setUserDetailObjectIsLoading', true)

    await repository.Role.GetRolesPermissions({ UserType: 'Dealer' })
      .then((res) => {
        commit('setRolePermissionList', res.data.items)
      })
      .catch((err) => {
        commit('setGetRolesError', err.response.data.messageLocalizable)
      })

    return repository.Dealer.GetUsersByDealer(payload)
      .then((res) => {
        const currentUser = res.data.items.find((user: User) => {
          return user.userId === payload.UserId
        })
        commit('setUserDetailObject', currentUser)
      })
      .catch(() => {})
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },
  async enableDisableUser({ commit }, payload) {
    commit('setUserDetailObjectIsLoading', true)
    const apiPayload = {
      UserId: payload.userId,
      IsEnabled: !payload.isActive,
    }
    return repository.User.EnableDisableUser(apiPayload)
      .then(() => {
        commit('setUserEnableDisableError', false)
        commit('setUpdateUserDetailEnableDisable', payload)
      })
      .catch((err) => {
        commit('setUserEnableDisableError', true)
        commit(
          'setUserEnableDisableErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },
  async getRolesPermissions({ commit }, payload) {
    return repository.Role.GetRolesPermissionsUsers(payload)
      .then((res) => {
        const roleListData = res.data.items
        roleListData.forEach((role: RolePermission) => {
          role.roleType = (i18n.t('global.default') as string) || 'default'
          let numberOfPermissionsAssigned = 0
          role.permissions.forEach((permission: Permission) => {
            if (permission.isAvailable) {
              numberOfPermissionsAssigned += 1
            }
          })
          role.numberOfPermissionsAssigned = numberOfPermissionsAssigned
          role.numberOfPeopleAssigned = role.users.length
          role.users.forEach((user) => {
            if (user.isActive) {
              user.status = 'Enabled'
            } else {
              user.status = 'Disabled'
            }
          })
        })
        commit('setRolePermissionList', res.data.items)
      })
      .catch((err) => {
        commit('setGetRolesError', err.response.data.messageLocalizable)
      })
  },
  async getUserAssignedLocations({ commit }, payload) {
    return repository.Location.GetAssignedUserLocations(payload)
      .then((res) => {
        const assignedLocations = []
        res.data.locations.forEach((location: Location) => {
          // @ts-ignore
          // TODO: fix
          if (location.assignedToUser) {
            assignedLocations.push(location)
          }
        })
        commit('setUserAssignedLocations', assignedLocations)
        commit('setUserAssignedToAllLocations', res.data.assignToAllLocations)
        commit('setUserAllLocations', res.data.locations)
      })
      .catch(() => {
        commit('setUserAssignedLocations', [])
        commit('setUserAssignedToAllLocations', false)
        commit('setUserAllLocations', [])
      })
  },
  updateUserAssignedLocations({ commit }, payload) {
    commit('setUserAssignedLocations', payload)
  },
  callUpdateAssignedLocation({ commit }, payload) {
    commit('updateAssignedLocation', payload)
  },
  assignUserLocations({ commit }, payload) {
    commit('setUserAssignedToAllLocations', payload.AssignAllLocations)
    if (payload.locations) commit('setUserAssignedLocations', payload.locations)
    return repository.Location.AssignCustomerLocations(payload)
  },
  async updateCustomerUserRole({ commit }, payload) {
    commit('setUserDetailObjectIsLoading', true)
    commit('setUpdateUserInfoError', false)
    await repository.User.Update(payload)
      .then(() => {})
      .catch((err) => {
        commit('setUpdateUserInfoError', true)
        commit(
          'setUpdateUserInfoErrorMessage',
          err.response.data.messageLocalizable
        )
      })
    return repository.Customer.GetUsers(payload)
      .then((res) => {
        const currentUser = res.data.items.find((user: User) => {
          return user.userId === payload.UserId
        })
        commit('setUserDetailObject', currentUser)
      })
      .catch(() => {})
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },
  async updateDealerUserRole({ commit }, payload) {
    commit('setUserDetailObjectIsLoading', true)
    commit('setUpdateUserInfoError', false)
    await repository.User.Update(payload)
      .then(() => {})
      .catch((err) => {
        commit('setUpdateUserInfoError', true)
        commit(
          'setUpdateUserInfoErrorMessage',
          err.response.data.messageLocalizable
        )
      })
    return repository.Dealer.GetUsers(payload)
      .then((res) => {
        const currentUser = res.data.items.find((user: User) => {
          return user.userId === payload.UserId
        })
        commit('setUserDetailObject', currentUser)
      })
      .catch(() => {})
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },
  async updateSuperAdminDealerUserRole({ commit }, payload) {
    commit('setUserDetailObjectIsLoading', true)
    commit('setUpdateUserInfoError', false)
    await repository.User.Update(payload)
      .then(() => {})
      .catch((err) => {
        commit('setUpdateUserInfoError', true)
        commit(
          'setUpdateUserInfoErrorMessage',
          err.response.data.messageLocalizable
        )
      })
    return repository.Dealer.GetUsersByDealer(payload)
      .then((res) => {
        const currentUser = res.data.items.find((user: User) => {
          return user.userId === payload.UserId
        })
        commit('setUserDetailObject', currentUser)
      })
      .catch(() => {})
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },
  async getDealerUserDetailById({ commit }, payload) {
    commit('setUserDetailObjectIsLoading', true)
    commit('setUserDetailObject', {})
    return repository.Customer.GetDealerUsersByCustomer(payload)
      .then((res) => {
        const currentUser = res.data.items.find((user: User) => {
          return user.userId === payload.UserId
        })
        commit('setUserDetailObject', currentUser)
      })
      .catch(() => {})
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },

  async getDealerUserDetail({ commit }, payload) {
    commit('setUserDetailObjectIsLoading', true)
    commit('setUserDetailObject', {})
    await repository.Role.GetRolesPermissions({ UserType: 'Dealer' })
      .then((res) => {
        commit('setRolePermissionList', res.data.items)
      })
      .catch((err) => {
        commit(
          'setGetRolePermissionsListErrorMessage',
          err.response.data.messageLocalizable
        )
      })
    return repository.Dealer.GetUsers(payload)
      .then((res) => {
        const currentUser = res.data.items.find((user: User) => {
          return user.userId === payload.UserId
        })
        commit('setUserDetailObject', currentUser)
      })
      .catch(() => {})
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },
  async deleteUser({ commit }, payload) {
    commit('setUserDetailObjectIsLoading', true)
    commit('setUserDeleteError', false)
    const apiPayload = {
      UserId: payload.userId,
    }
    return repository.User.DeleteUser(apiPayload)
      .then(() => {
        commit('setDeleteUser', payload)
      })
      .catch((err) => {
        commit('setUserDeleteError', true)
        commit(
          'setUserDeleteErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUserDetailObjectIsLoading', false)
      })
  },
}

const VigilUserDetail: Module<VigilUserDetailState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export default VigilUserDetail

interface VigilUserDetailState {
  userEditIsLoading: boolean
  userEditError: boolean
  userEditErrorMessage: string
  userDetailObject: Partial<User>
  userDetailObjectIsLoading: boolean
  userDeleteIsLoading: boolean
  userDeleteErrorMessage: string
  userDeleteError: boolean
  userEnableDisableError: boolean
  userEnableDisableErrorMessage: string
  rolePermissionsList: RolePermission[]
  userAssignedLocations: UserAssignedLocation[]
  userAllLocations: UserAllLocation[]
  userAssignedToAllLocations: boolean
  updateUserInfoError: boolean
  updateUserInfoErrorMessage: string
}
