import {
  DeviceObject,
  Location,
  VigilNVRObject,
  VigilNVRDevice,
} from '@/models'
import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): CustomerLocationsState => {
  return {
    addLocationIsLoading: false,
    addLocationError: false,
    addLocationErrorMessage: '',
    newLocationId: 0,
    customerLocationIsLoading: false,
    customerLocationList: [],
    locationDeviceList: [],
    locationDeviceTotalCount: 0,
    locationDeviceListIsLoading: false,
    updateLocationIsLoading: false,
    updateLocationError: false,
    updateLocationErrorMessage: '',
    customerNVRInfo: {
      code: '',
      groupStatus: '',
      timeZoneID: 0,
      deviceGroupID: 0,
      deviceGroupCode: '',
      devices: [],
      name: '',
    },
    customerNVRInfoIsLoading: false,
    customerNVRInfoError: false,
    updateCustomerNVRInfoIsLoading: false,
    updateCustomerNVRInfoError: false,
    updateNVRCameraNameLoading: false,
    updateNVRCameraNameError: false,
    updateNVRCameraNameValidationError: false,
    updateNVRCameraNameErrorMessage: '',
    deleteCustomerLocationIsLoading: false,
    deleteCustomerLocationSuccess: true,
    deleteCustomerLocationErrorMessage: '',
  }
}

const state = getDefaultState()

const getters: GetterTree<CustomerLocationsState, RootState> = {
  currentLocation: (state) => (locationId: number) => {
    return state.customerLocationList.find((location) => {
      return location.locationId === locationId
    })
  },
  locationNVRList(state) {
    return state.locationDeviceList.filter(
      (item: DeviceObject | VigilNVRObject) => {
        if ('deviceGroupID' in item) {
          if (item.deviceGroupID != null) return item
        }
      }
    )
  },
  currentDevice: (state) => (deviceId: number) => {
    const selectedDevice = state.locationDeviceList.find(
      (device: DeviceObject | VigilNVRDevice) => {
        return device.deviceId === deviceId
      }
    )
    if (typeof selectedDevice === 'undefined') return {}
    else return selectedDevice
  },
}

const mutations: MutationTree<CustomerLocationsState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setAddLocationIsLoading(state, value) {
    state.addLocationIsLoading = value
  },
  setAddLocationError(state, value) {
    state.addLocationError = value
  },
  setAddLocationErrorMessage(state, value) {
    state.addLocationErrorMessage = value
  },
  setNewLocationId(state, value) {
    state.newLocationId = value
  },
  setCustomerLocationsIsLoading(state, value) {
    state.customerLocationIsLoading = value
  },
  setCustomerLocationList(state, value) {
    state.customerLocationList = value
  },
  setLocationDeviceListIsLoading(state, value) {
    state.locationDeviceListIsLoading = value
  },
  setLocationDeviceList(state, value) {
    state.locationDeviceList = value
  },
  setLocationDeviceTotalCount(state, value) {
    state.locationDeviceTotalCount = value
  },
  updateLocationsList(state, value) {
    const id = value.deviceId || value.deviceGroupID
    state.locationDeviceList = state.locationDeviceList.reduce(
      (arr, item: DeviceObject | VigilNVRObject) => {
        if ('deviceGroupID' in item && item.deviceGroupID) {
          if (item.deviceGroupID == id) {
            item.groupStatus = value.status
          }
          item.devices = item.devices.map((t) => {
            return t.deviceId == id ? value : t
          })
          arr.push(item)
        } else {
          if ('deviceId' in item && item.deviceId == id) {
            item = value
          }
          arr.push(item)
        }
        return arr
      },
      []
    )
  },
  updateConnectStatusList(state, value) {
    const deviceID = value.DeviceId
    state.locationDeviceList.forEach((item: DeviceObject) => {
      if (item.deviceId === deviceID) {
        item.vigilConnectStatus = value.vigilConnectStatus
      }
    })
  },

  setUpdateLocationIsLoading(state, value) {
    state.updateLocationIsLoading = value
  },
  setUpdateLocationError(state, value) {
    state.updateLocationError = value
  },
  setUpdateLocationErrorMessage(state, value) {
    state.updateLocationErrorMessage = value
  },
  setUpdatedLocation(state, value) {
    const locationId = value.locationId || value.LocationId
    state.customerLocationList = state.customerLocationList.map((item) => {
      return item.locationId === locationId ? value : item
    })
  },
  updateCameraInfoById(state, value) {
    const deviceID = value.deviceId
    state.locationDeviceList = state.locationDeviceList.map((item) =>
      'deviceId' in item && item.deviceId === deviceID ? value : item
    )
  },
  updateCameraUpdateStatus(state, value) {
    const deviceID = value.deviceId
    state.locationDeviceList.forEach((item) => {
      if ('deviceId' in item && item.deviceId === deviceID) {
        Object.assign(item, value)
      }
    })
  },
  updateCameraInfoByDealer(state, value) {
    const deviceID = value.deviceId
    return state.locationDeviceList.forEach((item) => {
      if ('deviceGroupID' in item && item.deviceGroupID > 0) {
        return item.devices.forEach((device) => {
          if (device.deviceId === deviceID) {
            Object.assign(device, value)
          }
          return device
        })
      } else if ('deviceId' in item && item.deviceId === deviceID) {
        Object.assign(item, value)
      }
      return item
    })
  },
  updateNVRCameraName(state, value) {
    const deviceID = value.deviceId
    return state.locationDeviceList.forEach((item) => {
      if ('deviceGroupID' in item && item.deviceGroupID > 0) {
        return item.devices.forEach((device) => {
          if (device.deviceId === deviceID) {
            device.deviceName = value.deviceName
          }
          return device
        })
      } else {
        if ('deviceId' in item && item.deviceId === deviceID) {
          item.deviceName = value.deviceName
        }
        return item
      }
    })
  },
  updateNVRNameByGroupId(state, value) {
    const deviceGroupID = value.deviceGroupID
    state.locationDeviceList = state.locationDeviceList.map((item) => {
      if ('deviceGroupID' in item && item.deviceGroupID === deviceGroupID) {
        item.name = value.name
      }
      return item
    })
  },
  setCustomerNVRInfo(state, value) {
    state.customerNVRInfo = value
  },
  updateCustomerNVRName(state, value) {
    state.customerNVRInfo.name = value.name
  },
  setCustomerNVRInfoIsLoading(state, value) {
    state.customerNVRInfoIsLoading = value
  },
  setCustomerNVRInfoError(state, value) {
    state.customerNVRInfoError = value
  },
  setUpdateCustomerNVRInfoIsLoading(state, value) {
    state.updateCustomerNVRInfoIsLoading = value
  },
  setUpdateCustomerNVRInfoError(state, value) {
    state.updateCustomerNVRInfoError = value
  },
  setUpdateNVRCameraNameError(state, value) {
    state.updateNVRCameraNameError = value
  },
  setUpdateNVRCameraNameLoading(state, value) {
    state.updateNVRCameraNameLoading = value
  },
  setUpdateNVRCameraNameValidationError(state, value) {
    state.updateNVRCameraNameValidationError = value
  },
  setUpdateNVRCameraNameErrorMessage(state, value) {
    state.updateNVRCameraNameErrorMessage = value
  },
  setDeleteCustomerLocationIsLoading(state, value) {
    state.deleteCustomerLocationIsLoading = value
  },
  setDeleteCustomerLocationSuccess(state, value) {
    state.deleteCustomerLocationSuccess = value
  },
  setDeleteCustomerLocationErrorMessage(state, value) {
    state.deleteCustomerLocationErrorMessage = value
  },
  deleteCustomerLocation(state, value) {
    state.customerLocationList = state.customerLocationList.filter(
      (location) => {
        return location.locationId != value.locationId
      }
    )
  },
  updateDefaultLocationTimezone(state, value) {
    const location = state.customerLocationList.find(
      (x) => x.locationId === value.LocationId
    )
    location.lastDeviceTimeZone = value.TimeZoneId
  },
}

const actions: ActionTree<CustomerLocationsState, RootState> = {
  async getCustomerLocationList({ commit }, payload) {
    commit('setCustomerLocationList', [])
    commit('setCustomerLocationsIsLoading', true)
    return repository.Location.GetCustomerLocations(payload)
      .then((res) => {
        commit('setCustomerLocationList', res.data.items)
      })
      .catch(() => {
        //error message here
      })
      .finally(() => {
        commit('setCustomerLocationsIsLoading', false)
      })
  },
  async getLocationDeviceList({ commit }, payload) {
    if (!payload.refreshWithoutLoading) {
      commit('setLocationDeviceListIsLoading', true)
      commit('setLocationDeviceList', [])
    }
    return repository.Device.GetCustomerDevicesByLocationID(payload)
      .then((res) => {
        const fullDeviceArray = res.data.groups.concat(res.data.devices)
        commit('setLocationDeviceList', fullDeviceArray)
        commit('setLocationDeviceTotalCount', res.data.totalDeviceCount)
      })
      .catch(() => {
        //error message here
      })
      .finally(() => {
        commit('setLocationDeviceListIsLoading', false)
      })
  },
  async updateDeviceStatus({ commit }, payload) {
    return repository.Device.UpdateDeviceStatus(payload)
      .then((res) => {
        if (res.data.resultType === 0) {
          commit('updateLocationsList', res.data.item)
        }
      })
      .catch(() => {})
      .finally(() => {})
  },
  async updateNVRStatus({ commit }, payload) {
    return repository.Device.UpdateNVRStatus(payload)
      .then((res) => {
        commit('updateLocationsList', res.data)
      })
      .catch(() => {})
      .finally(() => {})
  },
  async updateVIGILConnectStatus({ commit }, payload) {
    return repository.Device.UpdateVIGILConnectStatus(payload)
      .then((res) => {
        payload.vigilConnectStatus = res.data
        commit('updateConnectStatusList', payload)
      })
      .catch(() => {})
      .finally(() => {})
  },
  async addLocation({ commit }, payload) {
    commit('setAddLocationIsLoading', true)
    return repository.Location.AddLocation(payload)
      .then((res) => {
        commit('setNewLocationId', res.data.locationId)
        commit('setAddLocationError', false)
      })
      .catch((err) => {
        commit('setAddLocationError', true)
        commit(
          'setAddLocationErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setAddLocationIsLoading', false)
      })
  },
  callUpdateLocationsList({ commit }, payload) {
    commit('updateLocationsList', payload)
  },
  async updateLocation({ commit }, payload) {
    commit('setUpdateLocationIsLoading', true)
    return repository.Location.EditCustomerLocation(payload)
      .then((res) => {
        commit('setUpdatedLocation', res.data)
        commit('setUpdateLocationError', false)
        commit('setUpdateLocationErrorMessage', '')
      })
      .catch((err) => {
        commit('setUpdateLocationError', true)
        commit(
          'setUpdateLocationErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdateLocationIsLoading', false)
      })
  },
  updateCameraInfoByDeviceId({ commit }, payload) {
    commit('updateCameraInfoByDealer', payload)
  },
  async updatePlaybackSourceByDealer({ commit }, payload) {
    commit('setUpdateLocationIsLoading', true)
    return repository.Device.UpdatePlaybackSource(payload)
      .then((res) => {
        if (res.data.item) {
          commit('setUpdateLocationError', false)
          commit('updateCameraInfoByDealer', res.data.item)
        }
      })
      .catch(() => {
        commit('setUpdateLocationError', true)
      })
      .finally(() => {
        commit('setUpdateLocationIsLoading', false)
      })
  },
  async getCameraUpdateStatusByDealer({ commit }, payload) {
    commit('setUpdateLocationIsLoading', true)
    return repository.Device.GetDeviceByID(payload)
      .then((res) => {
        if (res.data) {
          commit('setUpdateLocationError', false)
          commit('updateCameraUpdateStatus', res.data)
        }
      })
      .catch(() => {
        commit('setUpdateLocationError', true)
      })
      .finally(() => {
        commit('setUpdateLocationIsLoading', false)
      })
  },
  async getCustomerNVRInfo({ commit }, payload) {
    commit('setCustomerNVRInfoIsLoading', true)
    commit('setCustomerNVRInfo', {})
    return repository.Device.GetNVRInfo(payload)
      .then((res) => {
        if (res.data) {
          commit('setCustomerNVRInfoError', false)
          commit('setCustomerNVRInfo', res.data)
        }
      })
      .catch((err) => {
        commit('setCustomerNVRInfoError', true)
        commit(
          'setUpdateLocationErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setCustomerNVRInfoIsLoading', false)
      })
  },
  async updateCustomerNVRName({ commit }, payload) {
    commit('setUpdateCustomerNVRInfoIsLoading', true)
    return repository.Device.UpdateNVRName(payload)
      .then((res) => {
        if (res.data) {
          commit('setUpdateCustomerNVRInfoError', false)
          commit('updateCustomerNVRName', res.data)
          commit('updateNVRNameByGroupId', res.data)
        }
      })
      .catch((err) => {
        commit('setUpdateCustomerNVRInfoError', true)
        commit(
          'setUpdateLocationErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdateCustomerNVRInfoIsLoading', false)
      })
  },
  async updateNVRCameraName({ commit }, payload) {
    commit('setUpdateNVRCameraNameLoading', true)
    commit('setUpdateNVRCameraNameError', false)
    commit('setUpdateNVRCameraNameValidationError', false)
    commit('setUpdateNVRCameraNameErrorMessage', '')
    return repository.Device.UpdateDeviceName(payload)
      .then(() => {
        commit('updateNVRCameraName', payload)
      })
      .catch((err) => {
        if (err.response.status == 400) {
          commit('setUpdateNVRCameraNameError', true)
          commit('setUpdateNVRCameraNameValidationError', true)
          commit(
            'setUpdateNVRCameraNameErrorMessage',
            err.response.data.messageLocalizable
          )
        } else {
          commit('setUpdateNVRCameraNameError', true)
          commit(
            'setUpdateNVRCameraNameErrorMessage',
            err.response.data.messageLocalizable
          )
        }
      })
      .finally(() => {
        commit('setUpdateNVRCameraNameLoading', false)
      })
  },
  resetUpdateNVRCameraName({ commit }) {
    commit('setUpdateNVRCameraNameError', false)
    commit('setUpdateNVRCameraNameValidationError', false)
    commit('setUpdateNVRCameraNameErrorMessage', '')
  },
  async deleteCustomerLocation({ commit }, payload) {
    commit('setDeleteCustomerLocationIsLoading', true)
    return repository.InternalAdmin.DeleteCustomerLocation(payload)
      .then((res) => {
        if (res.data) {
          commit('setDeleteCustomerLocationSuccess', true)
          commit('deleteCustomerLocation', payload)
        } else {
          commit('setDeleteCustomerLocationSuccess', false)
        }
      })
      .catch((err) => {
        commit(
          'setDeleteCustomerLocationErrorMessage',
          err.response.data.messageLocalizable
        )
        commit('setDeleteCustomerLocationSuccess', false)
      })
      .finally(() => {
        commit('setDeleteCustomerLocationIsLoading', false)
      })
  },
  callDeleteCustomerLocationErrorMessage({ commit }, payload) {
    commit('setDeleteCustomerLocationErrorMessage', payload || '')
  },
  async updateLocationTimezone({ commit }, payload) {
    commit('updateDefaultLocationTimezone', payload)
  },
}
const CustomerLocations: Module<CustomerLocationsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default CustomerLocations

interface CustomerLocationsState {
  addLocationIsLoading: boolean
  addLocationError: boolean
  addLocationErrorMessage: string
  newLocationId: number
  customerLocationIsLoading: boolean
  customerLocationList: Location[]
  locationDeviceList: (DeviceObject | VigilNVRObject)[]
  locationDeviceTotalCount: number
  locationDeviceListIsLoading: boolean
  updateLocationIsLoading: boolean
  updateLocationError: boolean
  updateLocationErrorMessage: string
  customerNVRInfo: VigilNVRObject
  customerNVRInfoIsLoading: boolean
  customerNVRInfoError: boolean
  updateCustomerNVRInfoIsLoading: boolean
  updateCustomerNVRInfoError: boolean
  updateNVRCameraNameLoading: boolean
  updateNVRCameraNameError: boolean
  updateNVRCameraNameValidationError: boolean
  updateNVRCameraNameErrorMessage: string
  deleteCustomerLocationIsLoading: boolean
  deleteCustomerLocationSuccess: true
  deleteCustomerLocationErrorMessage: string
}
