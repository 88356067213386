
import { defineComponent, PropType } from 'vue'
import VueMarkdown from 'vue-markdown'
import {
  Feedback,
  AskResponse,
  ParsedAnswer,
  Citation,
} from '@/constants/aiChatModels'
import { cloneDeep } from 'lodash'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  name: 'Answer',
  components: {
    VueMarkdown,
  },
  props: {
    answer: {
      type: Object as PropType<AskResponse>,
      default: () => ({}),
    },
    vSessionId: {
      type: String,
      default: '',
    },
  },
  watch: {
    answer: {
      handler() {
        this.initAnswerFeedbackState()
      },
      immediate: true,
    },
  },
  data() {
    return {
      Feedback,
      SANITIZE_ANSWER: true,
      answerFeedbackState: Feedback.Neutral,
      isFeedbackDialogOpen: false,
      showReportInappropriateFeedback: false,
      negativeFeedbackList: [],
    }
  },
  computed: {
    ...mapState('Chat', ['feedbackState']),
    missingCitationLabel() {
      return this.$t('aiChat.missingCitation')
    },
    wrongCitationLabel() {
      return this.$t('aiChat.wrongCitation')
    },
    outOfScopeLabel() {
      return this.$t('aiChat.outOfScope')
    },
    inaccurateOrIrrelevantLabel() {
      return this.$t('aiChat.inaccurateOrIrrelevant')
    },
    reportInappropriateContentLabel() {
      return this.$t('aiChat.reportInappropriateContent')
    },
    theContentIsLabel() {
      return this.$t('aiChat.theContentIs')
    },
    hateSpeechLabel() {
      return this.$t('aiChat.hateSpeech')
    },
    violentLabel() {
      return this.$t('aiChat.violent')
    },
    sexualLabel() {
      return this.$t('aiChat.sexual')
    },
    manipulativeLabel() {
      return this.$t('aiChat.manipulative')
    },
    submitLabel() {
      return this.$t('aiChat.submitLabel')
    },
    otherFeedbackLabel() {
      return this.$t('aiChat.otherFeedback')
    },
    parsedAnswer() {
      return this.parseAnswer(this.answer)
    },
    currentFeedbackState() {
      const feedbackState =
        this.feedbackState[this.answer.message_id] ?? this.answerFeedbackState
      return feedbackState
    },
  },
  methods: {
    ...mapActions('Chat', ['historyMessageFeedback', 'setFeedbackState']),
    async onLikeResponseClicked() {
      if (this.answer.message_id == undefined) return

      let newFeedbackState = this.answerFeedbackState
      // Set or unset the thumbs up state
      if (this.answerFeedbackState == Feedback.Positive) {
        newFeedbackState = Feedback.Neutral
      } else {
        newFeedbackState = Feedback.Positive
      }
      this.setFeedbackState({
        answerId: this.answer.message_id,
        feedback: newFeedbackState,
      })

      // Update message feedback in db
      const response = await this.historyMessageFeedback({
        message_id: this.answer.message_id,
        message_feedback: newFeedbackState,
        config: {
          vSessionId: this.vSessionId,
        },
      })

      if (response?.ok) {
        this.answerFeedbackState = newFeedbackState
      } else {
        this.setFeedbackState({
          answerId: this.answer.message_id,
          feedback: this.answerFeedbackState,
        })
      }
    },
    initAnswerFeedbackState() {
      this.answerFeedbackState = this.answer.feedback ?? Feedback.Neutral
    },
    async onDislikeResponseClicked() {
      if (this.answer.message_id == undefined) return

      let newFeedbackState = this.answerFeedbackState
      if (
        this.answerFeedbackState === undefined ||
        this.answerFeedbackState === Feedback.Neutral ||
        this.answerFeedbackState === Feedback.Positive
      ) {
        this.isFeedbackDialogOpen = true
      } else {
        // Reset negative feedback to neutral
        newFeedbackState = Feedback.Neutral
        this.setFeedbackState({
          answerId: this.answer.message_id,
          feedback: newFeedbackState,
        })
        const response = await this.historyMessageFeedback({
          message_id: this.answer.message_id,
          message_feedback: newFeedbackState,
          config: {
            vSessionId: this.vSessionId,
          },
        })
        if (response?.ok) {
          this.setFeedbackState({
            answerId: this.answer.message_id,
            feedback: newFeedbackState,
          })
          this.answerFeedbackState = newFeedbackState
        } else {
          this.setFeedbackState({
            answerId: this.answer.message_id,
            feedback: this.answerFeedbackState,
          })
        }
      }
    },
    createCitationFilepath(
      citation: Citation,
      index: number,
      truncate = false
    ) {
      let citationFilename = ''
      if (citation.filepath) {
        if (truncate && citation.filepath.length > 50) {
          citationFilename = `${citation.filepath.substring(0, 20)}`
        } else {
          citationFilename = `${citation.filepath}`
        }
      } else {
        citationFilename = `Citation ${index}`
      }
      return citationFilename
    },
    onViewSource(citation) {
      if (citation.url) {
        const pageIndex = citation.content.indexOf('_pages_')
        if (pageIndex > 0) {
          const page = parseInt(
            citation.content.substring(pageIndex + 7).replace(/\\/g, ''),
            10
          )
          const url = citation.url + '#page=' + (page + 1)
          window.open(url, '_blank')
        } else {
          window.open(citation.url, '_blank')
        }
      }
    },
    resetFeedbackDialog() {
      this.isFeedbackDialogOpen = false
      this.showReportInappropriateFeedback = false
      this.negativeFeedbackList = []
    },
    async onSubmitNegativeFeedback() {
      if (this.answer.message_id == undefined) return
      const response = await this.historyMessageFeedback({
        message_id: this.answer.message_id,
        message_feedback: this.negativeFeedbackList.join(','),
        config: {
          vSessionId: this.vSessionId,
        },
      })
      if (response?.ok) {
        this.setFeedbackState({
          answerId: this.answer.message_id,
          feedback: Feedback.Negative,
        })
        this.answerFeedbackState = Feedback.Negative
      } else {
        this.setFeedbackState({
          answerId: this.answer.message_id,
          feedback: this.answerFeedbackState,
        })
      }
      this.resetFeedbackDialog()
    },

    enumerateCitations(citations: Citation[]) {
      const filepathMap = new Map()
      for (const citation of citations) {
        const { filepath } = citation
        let part_i = 1
        if (filepathMap.has(filepath)) {
          part_i = filepathMap.get(filepath) + 1
        }
        filepathMap.set(filepath, part_i)
        citation.part_index = part_i
      }
      return citations
    },

    parseAnswer(answer: AskResponse): ParsedAnswer {
      let answerText = answer.answer
      answerText = answerText.replace(
        /\[doc\d+(, p\.\d+)?(; doc\d+(, p\.\d+)?)*]/g,
        (match) => {
          const docMatches = match.match(/doc\d+/g)
          if (docMatches) {
            return docMatches.map((doc) => `[${doc}]`).join('')
          }
          return match
        }
      )
      const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g)

      const lengthDocN = '[doc'.length

      let filteredCitations = [] as Citation[]
      let citationReindex = 0
      citationLinks?.forEach((link) => {
        // Replacing the links/citations with number
        const citationIndex = link.slice(lengthDocN, link.length - 1)
        const citation = cloneDeep(
          answer.citations[Number(citationIndex) - 1]
        ) as Citation
        if (
          !filteredCitations.find((c) => c.id === citationIndex) &&
          citation
        ) {
          const escapedLink = link.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
          answerText = answerText.replace(
            new RegExp(escapedLink, 'g'),
            ` ^${++citationReindex}^ `
          )
          citation.id = citationIndex // original doc index to de-dupe
          citation.reindex_id = citationReindex.toString() // reindex from 1 for display
          filteredCitations.push(citation)
        }
      })

      filteredCitations = this.enumerateCitations(filteredCitations)

      return {
        citations: filteredCitations,
        markdownFormatText: answerText,
      }
    },
  },
})
