import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): NotificationsState => {
  return {
    notificationList: [],
    pagedNotificationList: [],
    pagedNotificationListCurrentPage: 1,
    pagedNotificationListTotalPages: 1,
    showGeneralSettings: false,
    viewAllNotifications: false,
    notificationSettingsGeneral: [],
    aaNotificationSettings: [],
    notificationSettingsGeneralHeaders: [],
    notificationSettingsLoading: false,
    initialNotificationCount: 0,
    pagedNotificationListLoading: false,
    pagedNotificationListError: null,
    deleteNotificationLoading: false,
    deleteNotificationError: false,
  }
}

const state = getDefaultState()

const getters: GetterTree<NotificationsState, RootState> = {
  notificationCount: (state) => {
    let totalCount = 0
    state.notificationList.forEach((not) => {
      if (!not.isRead) totalCount++
    })
    return totalCount + state.initialNotificationCount
  },
  notificationListTop15: (state) => {
    return state.notificationList.slice(0, 15)
  },
}

const mutations: MutationTree<NotificationsState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setReadNotification(state, value) {
    state.notificationList.forEach((not) => {
      if (not.notificationLogId == value) {
        not.isRead = true
      }
    })
  },
  setInitialNotificationCount(state, value) {
    state.initialNotificationCount = value
  },
  setReadAllNotifications(state) {
    state.notificationList.forEach((not) => {
      not.isRead = true
    })
  },
  setGeneralSettings(state, value) {
    state.showGeneralSettings = value
  },
  setViewAllNotifications(state, value) {
    state.viewAllNotifications = value
  },
  setNotificationSettings(state, value) {
    state.notificationSettingsGeneral = value
  },
  setAANotificationSettings(state, value) {
    state.aaNotificationSettings = value
  },
  setNotificationSettingsHeader(state, value) {
    state.notificationSettingsGeneralHeaders = value
  },
  setNotificationSettingsLoading(state, value) {
    state.notificationSettingsLoading = value
  },
  updateEmailSetting(state, value) {
    const currentNoti = state.notificationSettingsGeneral.find(
      ({ typeId }) => typeId === value.typeId
    )
    currentNoti.isEmailEnabled = value.isEmailEnabled
  },
  updateAASMSSetting(state, value) {
    const currentNotiIndex = state.aaNotificationSettings.findIndex(
      ({ locationId }) => locationId === value.locationId
    )
    state.aaNotificationSettings[currentNotiIndex].devices.forEach((device) => {
      if (device.deviceId == value.subscriptionDeviceId) {
        device.isSMSEnabled = value.isSMSEnabled
      }
    })
  },
  updateAAWebSetting(state, value) {
    const currentNotiIndex = state.aaNotificationSettings.findIndex(
      ({ locationId }) => locationId === value.locationId
    )
    state.aaNotificationSettings[currentNotiIndex].devices.forEach((device) => {
      if (device.deviceId == value.subscriptionDeviceId) {
        device.isWebEnabled = value.isWebEnabled
      }
    })
  },
  updateAAEmailSetting(state, value) {
    const currentNotiIndex = state.aaNotificationSettings.findIndex(
      ({ locationId }) => locationId === value.locationId
    )
    state.aaNotificationSettings[currentNotiIndex].devices.forEach((device) => {
      if (device.deviceId == value.subscriptionDeviceId) {
        device.isEmailEnabled = value.isEmailEnabled
      }
    })
  },
  updateLocationAASMSSetting(state, value) {
    const currentLocationIndex = state.aaNotificationSettings.findIndex(
      ({ locationId }) => locationId === value.locationId
    )
    state.aaNotificationSettings[currentLocationIndex].devices.forEach(
      (device) => (device.isSMSEnabled = value.isSMSEnabled)
    )
  },
  updateLocationAAWebSetting(state, value) {
    const currentLocationIndex = state.aaNotificationSettings.findIndex(
      ({ locationId }) => locationId === value.locationId
    )
    state.aaNotificationSettings[currentLocationIndex].devices.forEach(
      (device) => (device.isWebEnabled = value.isWebEnabled)
    )
  },
  updateLocationAAEmailSetting(state, value) {
    const currentLocationIndex = state.aaNotificationSettings.findIndex(
      ({ locationId }) => locationId === value.locationId
    )
    state.aaNotificationSettings[currentLocationIndex].devices.forEach(
      (device) => (device.isEmailEnabled = value.isEmailEnabled)
    )
  },
  updateWebSetting(state, value) {
    const currentNoti = state.notificationSettingsGeneral.find(
      ({ typeId }) => typeId === value.typeId
    )
    currentNoti.isWebEnabled = value.isWebEnabled
  },
  updateSMSSetting(state, value) {
    const currentNoti = state.notificationSettingsGeneral.find(
      ({ typeId }) => typeId === value.typeId
    )
    currentNoti.isSMSEnabled = value.isSMSEnabled
  },
  setAllNotifications(state, value) {
    state.notificationList = value
  },
  addNewNotifications(state, value) {
    const allNotifications = state.notificationList.concat(value)
    const dedupedNotifications = allNotifications.filter(
      (v, i, a) =>
        a.findIndex((t) => t.notificationLogId === v.notificationLogId) === i
    )
    dedupedNotifications.sort((a, b) => {
      return b.notificationLogId - a.notificationLogId
    })
    state.notificationList = dedupedNotifications
  },
  minusNotificationCount(state, value) {
    let removeCount = 0
    let isUnread = 0
    state.notificationList.forEach((v) => {
      if (value.indexOf(v.notificationLogId) > -1 && !v.isRead) removeCount++
    })
    state.pagedNotificationList.forEach((v) => {
      if (value.indexOf(v.notificationLogId) > -1 && !v.isRead) isUnread++
    })
    state.initialNotificationCount =
      state.initialNotificationCount - isUnread + removeCount > 0
        ? state.initialNotificationCount - isUnread + removeCount
        : 0
  },
  minusNotifications(state, value) {
    state.notificationList = state.notificationList.filter((v) => {
      return value.indexOf(v.notificationLogId) == -1
    })
  },
  setPagedNotificationList(state, value) {
    state.pagedNotificationList = value
  },
  setPagedNotificationListCurrentPage(state, value) {
    state.pagedNotificationListCurrentPage = value
  },
  setPagedNotificationListTotalPages(state, value) {
    state.pagedNotificationListTotalPages = value
  },
  setPagedNotificationListLoading(state, value) {
    state.pagedNotificationListLoading = value
  },
  setPagedNotificationListError(state, value) {
    state.pagedNotificationListError = value
  },
  setDeleteNotificationLoading(state, value) {
    state.deleteNotificationLoading = value
  },
  setDeleteNotificationError(state, value) {
    state.deleteNotificationError = value
  },
}

const actions: ActionTree<NotificationsState, RootState> = {
  async callSetReadAllNotifications({ commit }, payload) {
    return repository.Notification.UpdateNotificationLogRead(payload).then(
      () => {
        commit('setReadAllNotifications')
        commit('setInitialNotificationCount', 0)
      }
    )
  },
  async callSetReadNotification({ commit }, payload) {
    return repository.Notification.UpdateNotificationLogRead({
      NotificationLogId: payload,
    }).then(() => {
      commit('setReadNotification', payload)
    })
  },
  showNotificationsSettings({ commit }, payload) {
    if (payload.general == true) {
      commit('setGeneralSettings', true)
    } else {
      commit('setGeneralSettings', false)
    }
  },
  showAllNotifications({ commit }, payload) {
    if (payload.viewAll == true) {
      commit('setViewAllNotifications', true)
    } else {
      commit('setViewAllNotifications', false)
    }
  },
  async getNotificationSettings({ commit }) {
    commit('setNotificationSettingsLoading', true)
    return repository.Notification.GetNotificationSubscriptions()
      .then((res) => {
        const subscriptions = res.data.subscriptions
        const aaNotifications = res.data.advancedAnalyticsSubscriptions
        commit(
          'setNotificationSettings',
          subscriptions.sort(
            (a: Notification, b: Notification) => b.type < a.type
          )
        )
        commit(
          'setAANotificationSettings',
          aaNotifications.locations.sort(
            (a, b) => b.locationName < a.locationName
          )
        )
        commit('setNotificationSettingsHeader', res.data.notificationTypes)
      })
      .catch(() => {})
      .finally(() => {
        commit('setNotificationSettingsLoading', false)
      })
  },
  async toggleEmail({ commit }, payload) {
    const localPayload = Object.assign({}, payload)
    if (payload.subscriptionDeviceId) {
      delete payload.locationId
    }
    return repository.Notification.UpdateNotificationSubscription(payload).then(
      () => {
        if (payload.typeId == 18) {
          if (payload.subscriptionDeviceId) {
            commit('updateAAEmailSetting', localPayload)
          } else {
            commit('updateLocationAAEmailSetting', payload)
          }
        } else {
          commit('updateEmailSetting', payload)
        }
      }
    )
  },
  async toggleSMS({ commit }, payload) {
    const localPayload = Object.assign({}, payload)
    if (payload.subscriptionDeviceId) {
      delete payload.locationId
    }
    return repository.Notification.UpdateNotificationSubscription(payload).then(
      () => {
        if (payload.typeId == 18) {
          if (payload.subscriptionDeviceId) {
            commit('updateAASMSSetting', localPayload)
          } else {
            commit('updateLocationAASMSSetting', payload)
          }
        } else {
          commit('updateSMSSetting', payload)
        }
      }
    )
  },
  async toggleWeb({ commit }, payload) {
    const localPayload = Object.assign({}, payload)
    if (payload.subscriptionDeviceId) {
      delete payload.locationId
    }
    return repository.Notification.UpdateNotificationSubscription(payload).then(
      () => {
        if (payload.typeId == 18) {
          if (payload.subscriptionDeviceId) {
            commit('updateAAWebSetting', localPayload)
          } else {
            commit('updateLocationAAWebSetting', payload)
          }
        } else {
          commit('updateWebSetting', payload)
        }
      }
    )
  },
  setPreviousNotifications({ commit }, payload) {
    commit('setAllNotifications', payload.datas)
    commit('setInitialNotificationCount', payload.total - payload.datas.length)
  },
  addNewNotifications({ commit }, payload) {
    commit('addNewNotifications', payload)
  },
  async getAllNotifications({ commit }, payload) {
    return repository.Notification.GetNotificationLogsPaged(payload).then(
      (res) => {
        commit('addNewNotifications', res.data.items)
      }
    )
  },
  async getPagedNotifications({ commit }, payload) {
    commit('setPagedNotificationListLoading', true)
    commit('setPagedNotificationListError', null)
    return repository.Notification.GetNotificationLogsPaged(payload)
      .then((res) => {
        commit('setPagedNotificationList', res.data.items)
        commit('setPagedNotificationListCurrentPage', res.data.currentPage)
        commit('setPagedNotificationListTotalPages', res.data.totalPages)
      })
      .catch((err) => {
        commit(
          'setPagedNotificationListError',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setPagedNotificationListLoading', false)
      })
  },
  async deleteNotification({ commit }, payload) {
    commit('setDeleteNotificationLoading', true)
    commit('setDeleteNotificationError', false)
    return repository.Notification.DeleteNotification(payload)
      .then(() => {
        commit('minusNotificationCount', [payload.notificationId])
        commit('minusNotifications', [payload.notificationId])
      })
      .catch(() => {
        commit('setDeleteNotificationError', true)
      })
      .finally(() => {
        commit('setDeleteNotificationLoading', false)
      })
  },
  async deleteNotifications({ commit }, payload) {
    commit('setDeleteNotificationLoading', true)
    commit('setDeleteNotificationError', false)
    return repository.Notification.DeleteNotifications(payload)
      .then(() => {
        commit('minusNotificationCount', payload.notificationIds)
        commit('minusNotifications', payload.notificationIds)
      })
      .catch(() => {
        commit('setDeleteNotificationError', true)
      })
      .finally(() => {
        commit('setDeleteNotificationLoading', false)
      })
  },
}

const Notifications: Module<NotificationsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default Notifications

interface NotificationsState {
  notificationList: Notification[]
  pagedNotificationList: Notification[]
  pagedNotificationListCurrentPage: number
  pagedNotificationListTotalPages: number
  showGeneralSettings: boolean
  viewAllNotifications: boolean
  notificationSettingsGeneral: any[]
  aaNotificationSettings: any[]
  notificationSettingsGeneralHeaders: any[]
  notificationSettingsLoading: boolean
  initialNotificationCount: number
  pagedNotificationListLoading: boolean
  pagedNotificationListError: boolean
  deleteNotificationLoading: boolean
  deleteNotificationError: boolean
}

interface Notification {
  notificationId: number
  isRead: boolean
  notificationLogId: number
  typeId: number
  type: string
}
