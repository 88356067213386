import { render, staticRenderFns } from "./DownloadCaseBanner.vue?vue&type=template&id=0db144e2&"
import script from "./DownloadCaseBanner.vue?vue&type=script&lang=ts&"
export * from "./DownloadCaseBanner.vue?vue&type=script&lang=ts&"
import style0 from "./DownloadCaseBanner.vue?vue&type=style&index=0&id=0db144e2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBanner,VBtn})
