import store from '@/store/'

const routeHandler = async (to, from, next) => {
  if (store.state.Security.user.isDealerUser) {
    // dealer user checks
    // this needs to be await'ed
    await store.dispatch('Security/refreshLocalStorage')
    if (
      to.matched.some(
        (record) =>
          record.meta.dealerOnly ||
          record.meta.dealerAndCustomer ||
          record.meta.allUsers
      )
    ) {
      next()
    } else {
      // override default push to /dashboard if user enters /admin/users/:id
      if (to.name === 'VigilUserDetail') {
        next({ name: 'DealerUserDetail', params: { id: to.params.id } })
      } else {
        next('/dashboard')
      }
    }
  } else if (store.state.Security.user.isInternalUser) {
    //internal user checks
    if (
      to.matched.some(
        (record) => record.meta.internalOnly || record.meta.allUsers
      )
    ) {
      next()
    } else {
      next('/dealers')
    }
  } else if (to.matched.some((record) => record.meta.externalOnly)) {
    next()
  } else if (
    // this is the shortcircuit
    !store.state.Security.user.isInternalUser &&
    !store.state.Security.user.isDealerUser
  ) {
    // customer user checks
    if (
      to.matched.some(
        (record) =>
          record.meta.customerOnly ||
          !record.meta.internalOnly ||
          !record.meta.dealerOnly ||
          record.meta.allUsers ||
          record.meta.needAuthenticate
      )
    ) {
      next()
    } else {
      next('/dashboard')
    }
  } else if (to.matched.some((record) => record.meta.loggedInOnly)) {
    next()
  }
}

export default routeHandler
