import { GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/types'

const getDefaultState = (): AudioState => {
  return {
    audioInputDevices: [],
    audioOutputDevices: [],
    audioVolume: 0,
    previousAudioVolume: 0,
  }
}

const state = getDefaultState()

const mutations: MutationTree<AudioState> = {
  reset(state: AudioState) {
    Object.assign(state, getDefaultState())
  },
  setAudioInputDevices(state: AudioState, devices: []) {
    state.audioInputDevices = devices
  },
  setAudioOutputDevices(state: AudioState, devices: []) {
    state.audioOutputDevices = devices
  },
  setAudioVolume(state: AudioState, volume: number) {
    state.audioVolume = volume
  },
  setPreviousAudioVolume(state: AudioState, volume: number) {
    state.previousAudioVolume = volume
  },
}

const getters: GetterTree<AudioState, RootState> = {
  audioMuted: (state: AudioState) => {
    return state.audioVolume == 0
  },
}

const Audio: Module<AudioState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
}

export default Audio

interface AudioState {
  audioInputDevices: []
  audioOutputDevices: []
  audioVolume: number
  previousAudioVolume: number
}
