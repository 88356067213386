
import { defineComponent } from 'vue'
import AccountMenu from './../AccountMenu.vue'

export default defineComponent({
  name: 'InternalUserDesktopNav',
  components: {
    AccountMenu,
  },
  props: {
    showMyUsers: {
      type: Boolean,
      required: true,
    },
    navTabsOptional: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tabsModel: null,
    }
  },
  methods: {
    routeToDealers() {
      this.$router.push('/dealers')
    },
  },
  watch: {
    checkTabsModel() {
      if (this.navTabsOptional) this.tabsModel = ''
    },
  },
  computed: {
    checkTabsModel() {
      return this.navTabsOptional
    },
  },
})
