import { DeviceObject } from '@/models'
import { DateTime } from 'luxon'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): PlaybackControlsState => {
  return {
    cameraPlayLive: true,
    cameraPlayCurrent: false,
    cameraPlayEnabled: true,
    cameraPlayStartTime: DateTime.local().toSeconds(),
    cameraSliderPosition: 10000,
    cameraHD: true,
    cameraLowBandwidth: false,
    timelineLabel: [],
    timelineLabelIndex: 0,
    playbackSliderBeginTime: DateTime.local().toSeconds() - 21600, //21600seconds represents 6 hours
    playbackSliderEndTime: DateTime.local().toSeconds(), //Current Time Now
    playbackSliderDuration: 21600,
    playbackSliderResolution: 10000,
    playbackSliderAnimating: false,
    // The difference between playbackSliderCurrentTime and
    // playbackSliderCurrentTimeUnbounded is that the unbounded version can go
    // outside of the slider range
    playbackSliderCurrentTimeUnbounded: DateTime.local().toSeconds(),
    // Since frame time updates and mouse trigger updates can conflict, adding
    // a lock to prevent frame timestamp events to update slider position
    // to be safe, currently unlocked after calling player.init()
    playbackLock: true,
    timelineUnit: 'H',
    rangeSlider: [5900, 6300],
    isRangeSliderChange: false,
    forwardNumber: 30,
    backwardNumber: 30,
    frameOffset: 0,
    cameraFrameWidth: 0,
    cameraFrameHeight: 0,
    lastTimestamp: null,
    endOfPlayback: false,
    createClipMode: false,
    advancedTimelineMode: false,
  }
}

const state = getDefaultState()

const getters: GetterTree<PlaybackControlsState, RootState> = {
  // can be removed
  playbackSliderCurrentTime: (state) => {
    return (
      state.playbackSliderBeginTime +
      (state.cameraSliderPosition *
        (state.playbackSliderEndTime - state.playbackSliderBeginTime)) /
        state.playbackSliderResolution
    )
  },
  clipRangeSliderStartTime: (state) => {
    return (
      state.playbackSliderBeginTime +
      (state.rangeSlider[0] *
        (state.playbackSliderEndTime - state.playbackSliderBeginTime)) /
        state.playbackSliderResolution
    )
  },
  clipRangeSliderEndTime: (state) => {
    return (
      state.playbackSliderBeginTime +
      (state.rangeSlider[1] *
        (state.playbackSliderEndTime - state.playbackSliderBeginTime)) /
        state.playbackSliderResolution
    )
  },
  clipRangeSliderStartAndEndTime: (state) => (value: number) => {
    return (
      state.playbackSliderBeginTime +
      (value * (state.playbackSliderEndTime - state.playbackSliderBeginTime)) /
        state.playbackSliderResolution
    )
  },
  getTimeBiasBetweenLocalAndCameraInMinutes: () => (
    time: any,
    timezoneOffset: number,
    isDst: boolean
  ) => {
    const offset = time.offset
    if (isDst) {
      timezoneOffset += 1
    }
    return timezoneOffset * 60 - offset
  },
  getCameraNow: (_, getters) => (timezoneOffset: number, isDst: boolean) => {
    const now = DateTime.local()
    // @ts-ignore
    // TODO: fix
    const minutes = getters.getTimeBiasBetweenLocalAndCameraInMinutes(
      now,
      timezoneOffset,
      isDst
    )
    return now.plus({ minutes: minutes })
  },
  getCameraDateTimeFromLocalMillis: (state, getters) => (
    device: DeviceObject,
    datetime: string,
    isReplay = false
  ) => {
    const isDst = isReplay ? device.isDstReplay : device.isDst
    const time = DateTime.fromMillis(Number(datetime))
    // @ts-ignore
    // TODO: fix
    const minutes = getters.getTimeBiasBetweenLocalAndCameraInMinutes(
      time,
      device.timezoneID - 12,
      isDst
    )
    return time.plus({ minutes: minutes })
  },
  getUtcFromCameraDateTime: (state, getters) => (
    device: DeviceObject,
    datetime: string,
    isReplay = false
  ) => {
    const isDst = isReplay ? device.isDstReplay : device.isDst
    const time = DateTime.fromMillis(Number(datetime))
    // @ts-ignore
    // TODO: fix
    const minutes = getters.getTimeBiasBetweenLocalAndCameraInMinutes(
      time,
      device.timezoneID - 12,
      isDst
    )
    return time.minus({ minutes: minutes })
  },
  // can be removed
  getRoundTime: () => (currentTimeInSeconds: number, timelineUnit: string) => {
    const currentTime = DateTime.fromSeconds(currentTimeInSeconds)
    const minute = currentTime.minute
    let interval = 0
    if (timelineUnit == 'H') {
      if (minute >= 30) {
        interval = 90
      } else {
        interval = 30
      }
    } else if (timelineUnit == 'M') {
      if (minute >= 45) {
        interval = 75
      } else if (currentTime.minute >= 15) {
        interval = 45
      } else {
        interval = 15
      }
    } else if (timelineUnit == 'S') {
      const integerMinutes = Math.round(minute / 10) * 10
      interval = integerMinutes + 5
    }

    return currentTime
      .set({ second: 0 })
      .plus({ minute: interval - minute })
      .toSeconds()
  },
  getCeilTime: () => (currentTimeInSeconds: number, timelineUnit: string) => {
    let interval = 30 * 60
    if (timelineUnit == 'M') {
      interval = 15 * 60
    } else if (timelineUnit == 'S') {
      interval = 5 * 60
    }
    return Math.ceil(currentTimeInSeconds / interval) * interval
  },
}

const mutations: MutationTree<PlaybackControlsState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setCameraPlayLive(state, value) {
    state.cameraPlayLive = value
  },
  setCameraPlayCurrent(state, value) {
    state.cameraPlayCurrent = value
  },
  setCameraPlayEnabled(state, value) {
    state.cameraPlayEnabled = value
  },
  setCameraPlayStartTime(state, value) {
    state.cameraPlayStartTime = value
  },
  setCameraSliderPosition(state, value) {
    state.cameraSliderPosition = value
  },
  // can be removed
  setCameraSliderPositionByTimeInSeconds(state, value) {
    if (value <= state.playbackSliderBeginTime) {
      state.cameraSliderPosition = 0
    } else if (value >= state.playbackSliderEndTime) {
      state.cameraSliderPosition = state.playbackSliderResolution
    } else {
      const percentage =
        (value - state.playbackSliderBeginTime) /
        (state.playbackSliderEndTime - state.playbackSliderBeginTime)
      state.cameraSliderPosition = Math.round(
        Math.abs(percentage * state.playbackSliderResolution)
      )
    }
  },
  setPlaybackSliderCurrentTimeUnbounded(state, value) {
    state.playbackSliderCurrentTimeUnbounded = value
  },
  // can be removed
  setRangeSliderPositionByTimeInSeconds(state, array) {
    const rangeSlider = array
    const startPercentage =
      (rangeSlider[0] - state.playbackSliderBeginTime) /
      (state.playbackSliderEndTime - state.playbackSliderBeginTime)
    const endPercentage =
      (rangeSlider[1] - state.playbackSliderBeginTime) /
      (state.playbackSliderEndTime - state.playbackSliderBeginTime)
    rangeSlider[0] = Math.round(
      Math.abs(startPercentage * state.playbackSliderResolution)
    )
    rangeSlider[1] = Math.round(
      Math.abs(endPercentage * state.playbackSliderResolution)
    )
    state.rangeSlider = rangeSlider
  },
  setCameraHD(state, value) {
    state.cameraHD = value
  },
  // can be removed
  setCameraResolution(state, value) {
    state.cameraFrameWidth = value.cameraFrameWidth
    state.cameraFrameHeight = value.cameraFrameHeight
  },
  setCameraLowBandwidth(state, value) {
    state.cameraLowBandwidth = value
  },
  // can be removed
  setForwardNumber(state, value) {
    if (value) {
      state.forwardNumber++
    } else {
      state.forwardNumber--
    }
  },
  // can be removed
  setBackwardNumber(state, value) {
    if (value) {
      state.backwardNumber++
    } else {
      state.backwardNumber--
    }
  },
  setTimelineUnit(state, value) {
    state.timelineUnit = value
  },
  setTimelineLabel(state, value) {
    state.timelineLabel = value
  },
  // can be removed
  setTimelinelabelIndex(state, value) {
    state.timelineLabelIndex = state.timelineLabelIndex + value
  },
  setPlaybackSliderBeginTime(state, value) {
    state.playbackSliderBeginTime = value
  },
  setPlaybackSliderEndTime(state, value) {
    state.playbackSliderEndTime = value
  },
  setPlaybackSliderDuration(state, value) {
    state.playbackSliderDuration = value
  },
  setPlaybackSliderAnimating(state, value) {
    state.playbackSliderAnimating = value
  },
  setPlaybackLock(state, value) {
    state.playbackLock = value
  },
  setClipRangeSlider(state, value) {
    state.rangeSlider = value
  },
  // can be removed
  setPrevFrame(state) {
    state.frameOffset--
  },
  // can be removed
  setNextFrame(state) {
    state.frameOffset++
  },
  setLastTimestamp(state, value) {
    state.lastTimestamp = value
  },
  setRangeSliderChange(state, value) {
    state.isRangeSliderChange = value
  },
  setEndPlaybackState(state, value) {
    state.endOfPlayback = value
  },
  setCreateClipMode(state, value) {
    state.createClipMode = value
  },
  setAdvancedTimelineMode(state, value) {
    state.advancedTimelineMode = value
  },
}

const actions: ActionTree<PlaybackControlsState, RootState> = {
  // can be removed
  callCameraPlayLive({ commit }) {
    commit('reset')
  },
  // can be removed
  callCameraPlayCurrent({ commit, state }) {
    commit('setPlaybackLock', true)
    commit('setCameraPlayCurrent', true)
    commit('setCameraPlayLive', false)
    commit('setCameraSliderPosition', state.playbackSliderResolution)
    commit('setCameraPlayEnabled', true)
  },
  // can be removed
  callCameraSliderPosition({ commit }, payload) {
    commit('setPlaybackLock', true)
    commit('setCameraSliderPosition', payload)
  },
  // can be removed
  callCameraSliderPositionByTimeInSeconds({ commit }, payload) {
    commit('setCameraSliderPositionByTimeInSeconds', payload)
    commit('setPlaybackSliderCurrentTimeUnbounded', payload)
  },
  // can be removed
  callCameraPlayByTime({ commit }, payload) {
    commit('setPlaybackLock', true)
    commit('setCameraPlayLive', false)
    // calculate date and start here, based on state.timelineLabel
    commit('setCameraPlayStartTime', payload.startTime) //This sets the playback time so Device knows
    if (payload.sliderPosition) {
      commit('setCameraSliderPosition', payload.sliderPosition)
    } else {
      commit('setCameraSliderPositionByTimeInSeconds', payload.startTime)
    }
    commit('setCameraPlayCurrent', true)
    commit('setCameraPlayEnabled', true)
  },
  // can be removed
  callCameraSetSD({ commit }) {
    commit('setCameraHD', false)
  },
  // can be removed
  callCameraSetHD({ commit }) {
    commit('setCameraHD', true)
  },
  // can be removed
  callCameraResolution({ commit }, payload) {
    commit('setCameraResolution', payload)
  },
  // can be removed
  callCameraSetLowBandwidth({ commit }) {
    commit('setCameraLowBandwidth', true)
  },
  // can be removed
  callCameraSetHighBandwidth({ commit }) {
    commit('setCameraLowBandwidth', false)
  },
  // can be removed
  callLockSlider({ commit }) {
    commit('setPlaybackLock', true)
  },
  // can be removed
  callUnlockSlider({ commit }) {
    commit('setPlaybackLock', false)
  },
  // can be removed
  callPauseVideo({ commit }) {
    commit('setCameraPlayEnabled', false)
  },
  // can be removed
  callNextFrame({ commit }) {
    commit('setCameraPlayEnabled', false)
    commit('setNextFrame')
  },
  // can be removed
  callPrevFrame({ commit }) {
    commit('setCameraPlayEnabled', false)
    commit('setPrevFrame')
  },
  // can be removed
  callPlayVideo({ commit }) {
    commit('setCameraPlayEnabled', true)
  },
  // can be removed
  callTogglePausePlayVideo({ commit, state }) {
    if (state.cameraPlayEnabled) {
      commit('setCameraPlayEnabled', false)
    } else {
      commit('setCameraPlayEnabled', true)
    }
  },
  // can be removed
  callSetTimelineLabel({ commit, state, rootGetters }, payload) {
    const numIntervals = 6
    let newBeginTime: number
    let newEndTime: number
    let currentSliderTime: number
    let rangeSliderStartTime: number
    let rangeSliderEndTime: number
    // assuming label shift, unit, live and jump-to-time change don't occur at the same time
    if (payload.isLive) {
      // @ts-ignore
      // TODO: fix
      const integerTime = getters.getRoundTime()(
        payload.cameraTime.toSeconds(),
        state.timelineUnit
      )
      newEndTime = integerTime
      newBeginTime = newEndTime - state.playbackSliderDuration
    } else if (payload.newTime) {
      // @ts-ignore
      // TODO: fix
      const integerTime = getters.getRoundTime()(
        payload.newTime.toSeconds(),
        state.timelineUnit
      )
      newBeginTime = integerTime - state.playbackSliderDuration / 2
      newEndTime = integerTime + state.playbackSliderDuration / 2
    } else if (payload.unit) {
      if (payload.unit == 'S') {
        commit('setPlaybackSliderDuration', 3600)
      } else if (payload.unit == 'M') {
        commit('setPlaybackSliderDuration', 10800)
      } else {
        commit('setPlaybackSliderDuration', 21600)
      }
      commit('setTimelineUnit', payload.unit)
      if (payload.createClipMode) {
        // @ts-ignore
        // TODO: fix
        rangeSliderStartTime = getters.clipRangeSliderStartTime(state)
        // @ts-ignore
        // TODO: fix
        rangeSliderEndTime = getters.clipRangeSliderEndTime(state)
        let diffSeconds = rangeSliderEndTime - rangeSliderStartTime
        if (diffSeconds > state.playbackSliderDuration) {
          rangeSliderEndTime =
            rangeSliderEndTime - (diffSeconds - state.playbackSliderDuration)
          diffSeconds = rangeSliderEndTime - rangeSliderStartTime
        }
        newEndTime =
          (state.playbackSliderDuration - diffSeconds) / 2 + rangeSliderEndTime
      } else {
        // @ts-ignore
        // TODO: fix
        currentSliderTime = getters.playbackSliderCurrentTime(state)
        newEndTime = currentSliderTime + state.playbackSliderDuration / 2
      }
      const currentDateTime = payload.cameraDateTime.toSeconds()
      if (newEndTime > currentDateTime) {
        newEndTime = currentDateTime
      }
      // @ts-ignore
      // TODO: fix
      newEndTime = getters.getRoundTime()(newEndTime, payload.unit)
      newBeginTime = newEndTime - state.playbackSliderDuration
    } else if (payload.index) {
      // @ts-ignore
      // TODO: fix
      currentSliderTime = getters.playbackSliderCurrentTime(state)
      newEndTime =
        state.playbackSliderEndTime +
        (state.playbackSliderDuration / numIntervals) * payload.index
      newBeginTime = newEndTime - state.playbackSliderDuration
      commit('setTimelinelabelIndex', payload.index)
    }

    const display24HourTime = rootGetters['Security/display24HourTime']
    const format = display24HourTime ? 'HH:mm' : 'h:mm a'
    const dates = []
    let newTime: number
    const intervalSize = state.playbackSliderDuration / numIntervals
    for (let i = 0; i < numIntervals; i++) {
      // note that labels are half distance away from the interval, thus the
      // 0.5
      newTime = newEndTime - (i + 0.5) * intervalSize
      dates.unshift({ date: DateTime.fromSeconds(newTime).toFormat(format) })
    }
    commit('setTimelineLabel', dates)
    commit('setPlaybackSliderBeginTime', newBeginTime)
    commit('setPlaybackSliderEndTime', newEndTime)
    if (payload.isLive) {
      commit(
        'setCameraSliderPositionByTimeInSeconds',
        payload.cameraTime.toSeconds()
      )
    } else if (payload.newTime) {
      commit(
        'setCameraSliderPositionByTimeInSeconds',
        payload.newTime.toSeconds()
      )
    } else if (!state.cameraPlayLive && !payload.createClipMode) {
      commit('setCameraSliderPositionByTimeInSeconds', currentSliderTime)
    }
    if (payload.createClipMode) {
      commit('setRangeSliderPositionByTimeInSeconds', [
        rangeSliderStartTime,
        rangeSliderEndTime,
      ])
    }
  },
  // can be removed
  updateClipRangeSlider({ commit }, payload) {
    commit('setClipRangeSlider', payload)
  },
  // can be removed
  callSkipButton({ dispatch }, payload) {
    let timeChangeInSeconds = 0
    const direction = payload.direction ? payload.direction : 0
    if (direction > 0) {
      timeChangeInSeconds = direction * state.forwardNumber
    }
    if (direction < 0) {
      timeChangeInSeconds = direction * state.backwardNumber
    }
    const targetTime =
      // @ts-ignore
      // TODO: fix
      getters.playbackSliderCurrentTime(state) + timeChangeInSeconds
    dispatch('callCameraPlayByTime', {
      startTime: targetTime,
    })
  },
  // can be removed
  callSetDefaultPlaybackCameraDateTime({ commit, dispatch, getters }, payload) {
    const cameraDateTime = getters.getCameraDateTimeFromLocalMillis(
      payload.device,
      DateTime.local().toMillis()
    )
    const newPayload = {
      cameraTime: cameraDateTime,
      isLive: true,
    }
    commit('setCameraSliderPositionByTimeInSeconds', cameraDateTime.toSeconds())
    dispatch('callSetTimelineLabel', newPayload)
    return cameraDateTime
  },
  // can be removed
  callSetForwardNumber({ commit }, payload) {
    commit('setForwardNumber', payload)
  },
  // can be removed
  callSetBackwardNumber({ commit }, payload) {
    commit('setBackwardNumber', payload)
  },
  // can be removed
  callSetLastTimestamp({ commit }, payload) {
    commit('setLastTimestamp', payload)
  },
  // can be removed
  callRangeSliderChange({ commit }, payload) {
    commit('setRangeSliderChange', payload)
  },
  // can be removed
  setEndOfPlaybackState({ commit }) {
    commit('setEndPlaybackState', true)
  },
  // can be removed
  setStartOfPlaybackState({ commit }) {
    commit('setEndPlaybackState', false)
  },
  // can be removed
  updateCreateClipMode({ commit }, payload) {
    commit('setCreateClipMode', payload)
  },
  // can be removed
  updateAdvancedTimelineMode({ commit }, payload) {
    commit('setAdvancedTimelineMode', payload)
  },
  // can be removed
  callPlaybackSliderAnimating({ commit }) {
    commit('setPlaybackSliderAnimating', true)
  },
  // can be removed
  callPlaybackSliderAnimated({ commit }) {
    commit('setPlaybackSliderAnimating', false)
  },
  // can be removed
  updateTimeline({ dispatch, commit }, payload) {
    //@ts-ignore
    const cameraDateTime = getters.getCameraDateTimeFromLocalMillis(
      payload.device,
      DateTime.local().toMillis()
    )
    const newPayload = {
      cameraTime: cameraDateTime,
      isLive: payload.isLive,
    }
    dispatch('callSetTimelineLabel', newPayload)
  },
}

const PlaybackControls: Module<PlaybackControlsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default PlaybackControls

interface PlaybackControlsState {
  cameraPlayLive: boolean
  cameraPlayCurrent: boolean
  cameraPlayEnabled: boolean
  cameraPlayStartTime: number
  cameraSliderPosition: number
  cameraHD: boolean
  cameraFrameWidth: number
  cameraFrameHeight: number
  cameraLowBandwidth: boolean
  timelineLabel: []
  timelineLabelIndex: number
  playbackSliderBeginTime: number
  playbackSliderEndTime: number
  playbackSliderDuration: number
  playbackSliderResolution: number
  playbackSliderAnimating: boolean
  playbackSliderCurrentTimeUnbounded: number
  playbackLock: boolean
  timelineUnit: string // should really use the enum below
  rangeSlider: number[]
  isRangeSliderChange: boolean
  forwardNumber: number
  backwardNumber: number
  frameOffset: number
  lastTimestamp: null
  endOfPlayback: boolean
  createClipMode: boolean
  advancedTimelineMode: boolean
}
