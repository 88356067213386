import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { Country } from '@/models'
import * as Roles from '@/constants/roles.js'
import { RootState } from '@/store/types'
import * as Permissions from '@/constants/permissions.js'

const getDefaultState = (): SecurityState => {
  return {
    loginLoading: false,
    globalLoading: false,
    currentServer: '',
    getOwnUserLoading: false,
    user: {
      userId: 0,
      emailAddress: '',
      firstName: '',
      lastName: '',
      customerId: 0,
      dealerId: 0,
      isDealerUser: false,
      isInternalUser: false,
      isCardValid: false,
      isCardExpiring: false,
      isBillingPastDue: false,
      paymentType: '',
      permissions: [],
      phone: '',
      hasAcceptedTerms: false,
      hasAcceptedAudio: false,
      hasAcceptedEndUserLicenseAgreement: false,
      companyName: '',
      locale: null,
      isDealerImmixEnabled: false,
      onboardingMessages: [
        {
          messageGroupName: 'Dealer_Onboarding',
          content: null,
          messageGroupId: 1,
          hasViewed: false,
        },
        {
          messageGroupName: 'Single_Camera_Onboarding',
          content: null,
          messageGroupId: 2,
          hasViewed: false,
        },
        {
          messageGroupName: 'Customer_Onboarding',
          content: null,
          messageGroupId: 3,
          hasViewed: false,
        },
      ],
      role: '',
      billingTypeId: 0,
      timeFormat: '12-Hour Format',
      isCardExpired: false,
    },
    dealerViewingUser: false,
    dealerViewingLocation: '',
    dealerViewingUserFromDashboard: false,
    dealerCustomerId: 0,
    dealerLocationId: 0,
    loginErrorMessage: '',
    isLoggedIn: false,
    requiresTwoFactor: false,
    twoFactorErrorMessage: '',
    twoFactorSuccess: false,
    twoFactorIsLoading: false,
    getTwoFactorCodeIsLoading: false,
    encryptedVerificationCode: '',
    updateUserInfoIsLoading: false,
    updateUserInfoErrorMessage: '',
    updateUserPasswordIsLoading: false,
    updateUserPasswordErrorMessage: '',
    updateUserSuccess: false,
    checkSystemStatusLoading: false,
    systemStatusIsOnline: true,
    countryList: [],
    isTokenAuthenticated: false,
    externalSystemName: '',
    externalLastPlaybackTime: '',
  }
}

const state = getDefaultState()

const getters: GetterTree<SecurityState, RootState> = {
  userID(state) {
    return state.user.userId
  },
  user(state) {
    return state.user
  },
  customerID(state) {
    return state.user.customerId
  },
  dealerID(state) {
    return state.user.dealerId
  },
  companyName(state) {
    return state.user.companyName
  },
  isDealerUser(state) {
    return state.user.isDealerUser
  },
  isDealerAdmin(state) {
    return state.user.role?.includes(Roles.DEALER_ADMIN)
  },
  isInternalUser(state) {
    return state.user.isInternalUser
  },
  isInternalAdmin(state) {
    return state.user.role?.includes(Roles.INTERNAL_ADMIN)
  },
  hasPermission: (state) => (accessPermissions: string) => {
    if (state.user.permissions.length == 0) {
      return false
    }
    return state.user.permissions.includes(accessPermissions)
  },
  hasAcceptedAllTerms(state) {
    return (
      state.user.hasAcceptedTerms &&
      state.user.hasAcceptedEndUserLicenseAgreement &&
      state.user.hasAcceptedAudio
    )
  },
  hasSeenDealerOnboarding: (state) => {
    return state.user.onboardingMessages?.[0].hasViewed
  },
  display24HourTime: (state) => {
    // this is a bug that typescript found for us!!
    // at least, I think...
    return state.user.timeFormat == '24-Hour Format'
  },
  phoneCountries(state) {
    return state.countryList.map((item: Country) => {
      return item.type
    })
  },
  hasBankAccount: (state) => {
    return state.user.paymentType == 'bank_account'
  },
  dealerRequiresCC: (state) => {
    return !state.user.isCardValid
  },
  dealerCCExpiring: (state) => {
    return state.user.isCardExpiring
  },
  dealerIsBillable: (state) => {
    // 3 and 4 are auto-charged billable types
    return state.user.billingTypeId === 3 || state.user.billingTypeId === 4
  },
  dealerIsPastDue: (state) => {
    return state.user.isBillingPastDue
  },
  dealerImmixEnabled: (state) => {
    return state.user.isDealerImmixEnabled
  },
  dealerIsInvoiceNonExempt: (state) => {
    // invoiced, non-exempt dealers
    return state.user.billingTypeId === 5
  },
}

const mutations: MutationTree<SecurityState> = {
  setUser(state, user) {
    state.user = {
      userId: user.userId,
      emailAddress: user.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName,
      customerId: user.customerId,
      dealerId: user.dealerId,
      isDealerUser: user.isDealerUser,
      isInternalUser: user.isInternalUser,
      isBillingPastDue: user.isBillingPastDue,
      permissions: user.permissions,
      phone: user.phone,
      hasAcceptedTerms: user.hasAcceptedTerms,
      hasAcceptedAudio: user.hasAcceptedAudio,
      hasAcceptedEndUserLicenseAgreement:
        user.hasAcceptedEndUserLicenseAgreement,
      companyName: user.companyName,
      locale: user.locale,
      onboardingMessages: user.onboardingMessages,
      // this is a bug. default state, timeFormat is NOT within the user object. However, it gets set via this mutation, which would explain why the getter mostly 'worked'. (mostly)...
      timeFormat: user.timeFormat,
      isCardValid: user.isCardValid,
      isCardExpiring: user.isCardExpiring,
      paymentType: user.paymentType,
      role: user.role,
      billingTypeId: user.billingTypeId,
      isCardExpired: user.isCardExpired || state.user.isCardExpired,
      isDealerImmixEnabled: user.isDealerImmixEnabled,
    }
  },
  setGetOwnUserLoading(state, value) {
    state.getOwnUserLoading = value
  },
  setGlobalLoading(state, value) {
    state.globalLoading = value
  },
  setUpdateUserDetails(state, value) {
    state.user.firstName = value.FirstName
    state.user.lastName = value.LastName
    state.user.phone = value.Phone
    state.user.timeFormat = value.TimeFormat
    state.user.locale = value.locale
  },
  setUpdateOwnUser(state, value) {
    state.user.firstName = value.FirstName
    state.user.lastName = value.LastName
    state.user.emailAddress = value.EmailAddress
    state.user.phone = value.Phone
  },
  setUpdateUserPermissions(state, value) {
    state.user.permissions = value
  },
  setHasAcceptedTerms(state, value) {
    state.user.hasAcceptedTerms = value
    state.user.hasAcceptedAudio = value
    state.user.hasAcceptedEndUserLicenseAgreement = value
  },
  setOnboardingViewed(state, value) {
    state.user.onboardingMessages.forEach((element) => {
      if (element.messageGroupId == value) {
        element.hasViewed = true
      }
    })
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setLoginLoading(state, value) {
    state.loginLoading = value
  },
  setIsLoggedIn(state, value) {
    state.isLoggedIn = value
  },
  setLoginErrorMessage(state, value) {
    state.loginErrorMessage = value
  },
  setDealerViewingUser(state, value) {
    state.dealerViewingUser = value.dealerViewingUser
    state.dealerViewingLocation = value.dealerViewingLocation
    state.dealerViewingUserFromDashboard = value.dealerViewingUserFromDashboard
    state.dealerCustomerId = value.dealerCustomerId
    state.dealerLocationId = value.dealerLocationId || 0
  },
  setUpdateUserInfoIsLoading(state, value) {
    state.updateUserInfoIsLoading = value
  },
  setUpdateUserInfoErrorMessage(state, value) {
    state.updateUserInfoErrorMessage = value
  },
  setUpdateUserPasswordIsLoading(state, value) {
    state.updateUserPasswordIsLoading = value
  },
  setUpdateUserPasswordErrorMessage(state, value) {
    state.updateUserPasswordErrorMessage = value
  },
  setUpdateUserSuccess(state, value) {
    state.updateUserSuccess = value
  },
  setRequiresTwoFactor(state, value) {
    state.requiresTwoFactor = value
  },
  setTwoFactorErrorMessage(state, value) {
    state.twoFactorErrorMessage = value
  },
  setTwoFactorSuccess(state, value) {
    state.twoFactorSuccess = value
  },
  setTwoFactorIsLoading(state, value) {
    state.twoFactorIsLoading = value
  },
  setEncryptedTwoFactorCode(state, value) {
    state.encryptedVerificationCode = value
  },
  setGetTwoFactorCodeIsLoading(state, value) {
    state.getTwoFactorCodeIsLoading = value
  },
  setCurrentServer(state, value) {
    state.currentServer = value
  },
  setCheckSystemStatusLoading(state, value) {
    state.checkSystemStatusLoading = value
  },
  setSystemStatusIsOnline(state, value) {
    state.systemStatusIsOnline = value
  },
  setCountryList(state, value) {
    const alphaSortedList = value.sort((a: Country, b: Country) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

    state.countryList = alphaSortedList.sort(
      (a: Country, b: Country) => Number(b.isDefault) - Number(a.isDefault)
    )
  },
  setCardIsValid(state, value) {
    state.user.isCardValid = value
  },
  setCardIsExpiring(state, value) {
    state.user.isCardExpiring = value
  },
  setCardIsExpired(state, value) {
    state.user.isCardExpired = value
  },
  setPaymentType(state, value) {
    state.user.paymentType = value
  },
  setIsTokenAuthenticated(state, value) {
    state.isTokenAuthenticated = value
  },
  setExternalSystemName(state, value) {
    state.externalSystemName = value
  },
  setExternalLastPlaybackTime(state, value) {
    state.externalLastPlaybackTime = value
  },
}

const actions: ActionTree<SecurityState, RootState> = {
  async setUser({ commit, dispatch }, payload) {
    commit('setUser', payload)
    await Promise.all([
      dispatch('getAllCountryCodes'),
      dispatch('Environment/refreshSettings', null, { root: true }),
      dispatch('Environment/refreshFeatures', null, { root: true }),
      dispatch('refreshLocalStorage'),
    ])
    commit('setIsLoggedIn', true)
  },
  async authenticate(
    { commit, dispatch },
    payload: { emailAddress: string; password: string }
  ) {
    commit('setLoginLoading', true)

    await repository.User.Authenticate(payload)
      .then(async (res) => {
        // need to commit this only on api true response
        if (res.data.isTwoFactorAuthenticationRequired) {
          commit('setRequiresTwoFactor', true)
          commit(
            'setEncryptedTwoFactorCode',
            res.data.encryptedVerificationCode
          )
        } else {
          await dispatch('setUser', res.data)
        }
        commit('setLoginErrorMessage', '')
      })
      .catch((err) => {
        commit('setLoginErrorMessage', err.response.data.messageLocalizable)
      })
      .finally(() => {
        commit('setLoginLoading', false)
      })
  },
  async tokenAuthenticate({ commit }) {
    commit('setGlobalLoading', true)
    commit('setIsTokenAuthenticated', true)
    const data = window.location.search
    const urlParams = new URLSearchParams(data)

    if (!urlParams.has('accessToken')) {
      return repository.Case.GetCases({})
        .then(() => {
          commit('setIsLoggedIn', true)
          commit('setUpdateUserPermissions', [
            Permissions.VIEW_DEVICES,
            Permissions.VIEW_CASE,
            Permissions.VIEW_VIDEO,
            Permissions.CREATE_EDIT_CASE,
            Permissions.ADD_CASE_COMMENTS,
          ])
        })
        .catch(() => {
          commit('setIsLoggedIn', false)
        })
        .finally(() => {
          setTimeout(() => {
            commit('setGlobalLoading', false)
          }, 750)
        })
    } else {
      if (urlParams.get('originalUrl') != null)
        localStorage.setItem(
          'externalUrl',
          atob(urlParams.get('originalUrl')) || ''
        )
      if (urlParams.get('time') != null) {
        commit('setExternalLastPlaybackTime', urlParams.get('time') || '')
      }
      const payload = {
        AccessToken: atob(urlParams.get('accessToken') || ''),
      }
      return repository.Token.Authenticate(payload)
        .then((res) => {
          commit('setIsLoggedIn', true)
          commit('setUpdateUserPermissions', [
            Permissions.VIEW_DEVICES,
            Permissions.VIEW_CASE,
            Permissions.VIEW_VIDEO,
            Permissions.CREATE_EDIT_CASE,
            Permissions.ADD_CASE_COMMENTS,
          ])
          if (res.data.externalSystemName) {
            commit('setExternalSystemName', res.data.externalSystemName)
          }
        })
        .catch(() => {
          commit('setIsLoggedIn', false)
        })
        .finally(() => {
          setTimeout(() => {
            commit('setGlobalLoading', false)
          }, 750)
        })
    }
  },
  async getOwnUser({ commit, dispatch }) {
    commit('setGetOwnUserLoading', true)
    commit('setGlobalLoading', true)
    return repository.User.GetCurrentUser()
      .then(async (res) => {
        await dispatch('setUser', res.data)
      })
      .catch(() => {
        commit('setIsLoggedIn', false)
      })
      .finally(() => {
        setTimeout(() => {
          commit('setGetOwnUserLoading', false)
          commit('setGlobalLoading', false)
        }, 750)
      })
  },
  async getOwnUserNoLoading({ commit, dispatch }) {
    repository.User.GetCurrentUser()
      .then(async (res) => {
        await dispatch('setUser', res.data)
      })
      .catch(() => {
        commit('setIsLoggedIn', false)
      })
  },
  async callSubmitTwoFactor({ commit, dispatch }, payload) {
    commit('setTwoFactorIsLoading', true)
    await repository.User.VerifyTwoFactor(payload)
      .then(async (res) => {
        if (res.data.message == '' || res.data.message == null) {
          // success
          commit('setTwoFactorErrorMessage', '')
          commit('setTwoFactorSuccess', true)
          await dispatch('setUser', res.data)
        } else {
          commit('setTwoFactorErrorMessage', res.data.messageLocalizable)
          commit('setTwoFactorSuccess', false)
          commit(
            'setEncryptedTwoFactorCode',
            res.data.encryptedVerificationCode
          )
        }
      })
      .catch((err) => {
        commit('setTwoFactorErrorMessage', err.response.data.messageLocalizable)
      })
      .finally(() => {
        commit('setTwoFactorIsLoading', false)
      })
    await dispatch('getAllCountryCodes')
  },
  clearTwoFactorError({ commit }) {
    commit('setTwoFactorErrorMessage', '')
  },
  callIsLoggedIn({ commit }, payload) {
    commit('setIsLoggedIn', payload)
  },
  async getTwoFactorCode({ commit }, payload) {
    commit('setGetTwoFactorCodeIsLoading', true)
    return repository.User.GetTwoFactorCode(payload)
      .then((res) => {
        commit('setEncryptedTwoFactorCode', res.data.encryptedVerificationCode)
      })
      .catch((err) => {
        commit('setTwoFactorErrorMessage', err.response.data.messageLocalizable)
      })
      .finally(() => {
        setTimeout(() => {
          commit('setGetTwoFactorCodeIsLoading', false)
        }, 500)
      })
  },
  async logout({ commit }) {
    localStorage.removeItem('VIGIL')
    return repository.User.Logout().then(() => {
      commit('reset')
    })
  },
  callSetDealerViewingUser({ commit }, payload) {
    commit('setDealerViewingUser', payload)
    localStorage.setItem('VIGIL', JSON.stringify(payload))
  },
  async refreshLocalStorage({ commit, state }) {
    const storage = JSON.parse(localStorage.getItem('VIGIL'))
    if (storage != null && state.user.isDealerUser) {
      commit('setDealerViewingUser', storage)
    } else {
      const payload = {
        dealerViewingUser: false,
        dealerCustomerId: 0,
        dealerLocationId: 0,
        dealerViewingLocation: '',
        dealerViewingUserFromDashboard: false,
      }
      commit('setDealerViewingUser', payload)
    }
  },
  async updateUserInfo({ commit }, payload) {
    commit('setUpdateUserInfoIsLoading', true)
    return repository.User.UpdateMyAccount(payload)
      .then(() => {
        commit('setUpdateUserSuccess', true)
        commit('setUpdateUserDetails', payload)
      })
      .catch((err) => {
        commit(
          'setUpdateUserInfoErrorMessage',
          err.response.data.messageLocalizable
        )
        commit('setUpdateUserSuccess', false)
      })
      .finally(() => {
        commit('setUpdateUserInfoIsLoading', false)
      })
  },
  async acceptTerms({ commit }, payload) {
    commit('setUpdateUserInfoIsLoading', true)
    return repository.User.UpdateTerms(payload)
      .then(() => {
        commit('setHasAcceptedTerms', true)
        commit('setUpdateUserSuccess', true)
      })
      .catch((err) => {
        commit(
          'setUpdateUserInfoErrorMessage',
          err.response.data.messageLocalizable
        )
        commit('setUpdateUserSuccess', false)
      })
      .finally(() => {
        commit('setUpdateUserInfoIsLoading', false)
      })
  },
  viewedOnboarding({ commit }, messageGroupId) {
    commit('setOnboardingViewed', messageGroupId)
  },
  async updateUserPassword({ commit }, payload) {
    commit('setUpdateUserPasswordIsLoading', true)
    return repository.User.UpdatePassword(payload)
      .then(() => {})
      .catch((err) => {
        commit(
          'setUpdateUserPasswordErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdateUserPasswordIsLoading', false)
      })
  },
  clearPasswordError({ commit }) {
    commit('setUpdateUserPasswordErrorMessage', '')
  },
  clearLoginErrorMessage({ commit }) {
    commit('setLoginErrorMessage', '')
  },
  async getCurrentServer({ commit }) {
    return repository.Test.GetInstanceName().then((res) => {
      commit('setCurrentServer', res.data)
    })
  },
  async checkSystemStatus({ commit, dispatch }) {
    commit('setCheckSystemStatusLoading', true)
    return repository.Status.GetAPIStatus()
      .then(() => {
        dispatch('callSetSystemStatus', true)
      })
      .catch(() => {
        dispatch('callSetSystemStatus', false)
      })
      .finally(() => {
        commit('setCheckSystemStatusLoading', false)
      })
  },
  async callSetSystemStatus({ commit }, payload) {
    commit('setSystemStatusIsOnline', payload)
  },
  updateOwnUser({ commit }, payload) {
    commit('setUpdateOwnUser', payload)
  },
  async getAllCountryCodes({ commit }) {
    return repository.CountryCode.GetAllCountryCodes().then((res) => {
      commit('setCountryList', res.data)
    })
  },
  updateDealerCardInfo({ commit }, payload) {
    if (payload.payment_type) {
      commit('setPaymentType', payload.payment_type)
    }
    commit('setCardIsValid', true)
    commit('setCardIsExpiring', false)
    commit('setCardIsExpired', false)
  },
}

const Security: Module<SecurityState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default Security

interface OnboardingMessages {
  messageGroupName: string
  content: any
  messageGroupId: number
  hasViewed: boolean
}
interface SecurityState {
  loginLoading: boolean
  globalLoading: boolean
  currentServer: string
  getOwnUserLoading: boolean
  user: {
    billingTypeId: number
    companyName: string
    customerId: number
    dealerId: number
    emailAddress: string
    firstName: string
    hasAcceptedEndUserLicenseAgreement: boolean
    hasAcceptedTerms: boolean
    hasAcceptedAudio: boolean
    isCardExpired: boolean
    isCardExpiring: boolean
    isCardValid: boolean
    isDealerUser: boolean
    isInternalUser: boolean
    isBillingPastDue: boolean
    lastName: string
    locale: any
    onboardingMessages: OnboardingMessages[]
    paymentType: string
    permissions: string[]
    phone: string
    role: string
    timeFormat: string
    userId: number
    isDealerImmixEnabled: boolean
  }
  dealerViewingUser: boolean
  dealerViewingLocation: string
  dealerViewingUserFromDashboard: boolean
  dealerCustomerId: number
  dealerLocationId: number
  loginErrorMessage: string
  isLoggedIn: boolean
  requiresTwoFactor: boolean
  twoFactorErrorMessage: string
  twoFactorSuccess: boolean
  twoFactorIsLoading: boolean
  getTwoFactorCodeIsLoading: boolean
  encryptedVerificationCode: string
  updateUserInfoIsLoading: boolean
  updateUserInfoErrorMessage: string
  updateUserPasswordIsLoading: boolean
  updateUserPasswordErrorMessage: string
  updateUserSuccess: boolean
  checkSystemStatusLoading: boolean
  systemStatusIsOnline: boolean
  countryList: Country[]
  isTokenAuthenticated: boolean
  externalSystemName: string
  externalLastPlaybackTime: string
}
