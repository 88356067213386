
import { defineComponent } from 'vue'
import CustomerUserDesktopNav from './CustomerUserDesktopNav.vue'
import CustomerUserMobileNav from './CustomerUserMobileNav.vue'
import { mapGetters, mapState } from 'vuex'

export default defineComponent({
  name: 'CustomerUserNav',
  components: {
    CustomerUserDesktopNav,
    CustomerUserMobileNav,
  },
  props: {
    showAdmin: {
      type: Boolean,
      required: true,
    },
    showCaseLibrary: {
      type: Boolean,
      required: true,
    },
    navTabsOptional: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('Environment', ['hasMultiView']),
    ...mapState('Security', ['isLoggedIn', 'isTokenAuthenticated']),
    ...mapState('ExternalCases', ['shareQueryString']),
    externalCloudCases() {
      return this.isTokenAuthenticated
    },
  },
})
