
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import passwordStrengthRules from '@/mixins/passwordStrengthRules'
import TwoColumnLayout from '@/components/layout/TwoColumnLayout.vue'
import {
  RESET_PASSWORD_PAGE_VIEWED,
  BACK_TO_LOGIN_CLICK,
} from '@/constants/mixpanelEvents'

export default defineComponent({
  name: 'ResetPassword',
  mixins: [passwordStrengthRules],
  components: {
    TwoColumnLayout,
  },
  data() {
    return {
      resetPasswordForm: '',
      password: '',
      confirmPassword: '',
      showResetSuccess: false,
    }
  },
  computed: {
    ...mapState('ResetPassword', [
      'resetPasswordIsLoading',
      'resetPasswordSuccess',
      'resetPasswordError',
      'resetPasswordErrorMessage',
    ]),
    resetPasswordErrorMessageText() {
      return this.$t(this.resetPasswordErrorMessage)
    },
    resetBtnActive() {
      if (
        this.password == this.confirmPassword &&
        this.confirmPassword != '' &&
        this.valid_password
      ) {
        return true
      } else {
        return false
      }
    },
    enterPasswordLabel() {
      return this.$t('global.passwordEnterNew')
    },
    confirmPasswordLabel() {
      return this.$t('global.passwordConfirm')
    },
  },
  methods: {
    ...mapActions('ResetPassword', ['callResetPassword']),
    clickResetBtn() {
      if (this.resetBtnActive) {
        let payload = {
          VerificationToken: this.$router.currentRoute.query.id,
          NewPassword: btoa(this.password),
        }
        this.callResetPassword(payload)
      }
    },
    checkLocalPassword() {
      this.checkPassword()
    },
    clickTrack() {
      this.$mixpanelTrack(BACK_TO_LOGIN_CLICK)
    },
  },
  mounted() {
    // quick check to ensure user has reset password token
    if (this.$router.currentRoute.query.id == null) this.$router.push('/login')
    this.$mixpanelTrack(RESET_PASSWORD_PAGE_VIEWED)
  },
})
