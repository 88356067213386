import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'
import repository from '@/repository'

const getDefaultState = (): AccessTokensState => {
  return {
    accessKeysIsLoading: false,
    accessKeys: [],
    locationsDeviceList: [],
    newKeyString: '',
    newKeyLoading: false,
    updateTokenStatusIsLoading: false,
    userAssignedLocationsIsLoading: false,
    updateTokenErrorMessage: '',
    saveTokenErrorMessage: '',
    updateTokenSuccess: true,
    hasTokenAccess: false,
  }
}

const state = getDefaultState()

const mutations: MutationTree<AccessTokensState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setAccessKeysIsLoading(state, value) {
    state.accessKeysIsLoading = value
  },
  setAccessKeys(state, value) {
    state.accessKeys = value
  },
  setLocationsDeviceList(state, value) {
    state.locationsDeviceList = value
  },
  setNewKeyString(state, value) {
    state.newKeyString = value
  },
  setNewKeyLoading(state, value) {
    state.newKeyLoading = value
  },
  setTokenStatusIsLoading(state, value) {
    state.updateTokenStatusIsLoading = value
  },
  setUserAssignedLocationsIsLoading(state, value) {
    state.userAssignedLocationsIsLoading = value
  },
  setUpdateTokenErrorMessage(state, value) {
    state.updateTokenErrorMessage = value
  },
  setUpdateTokenSuccess(state, value) {
    state.updateTokenSuccess = value
  },
  setHasTokenAccess(state, value) {
    state.hasTokenAccess = value
  },
  setSaveTokenErrorMessage(state, value) {
    state.saveTokenErrorMessage = value
  },
}

const actions: ActionTree<AccessTokensState, RootState> = {
  getCustomerAccessKeys({ commit }) {
    commit('setAccessKeysIsLoading', true)
    return repository.Token.getToken()
      .then((res) => {
        commit('setAccessKeys', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setAccessKeysIsLoading', false)
      })
  },
  async getHasTokenAccess({ commit }) {
    commit('setHasTokenAccess', false)
    return repository.Token.hasTokenAccess()
      .then((res) => {
        commit('setHasTokenAccess', res.data)
      })
      .catch(() => {})
  },
  async getUserAssignedLocations({ commit }, payload) {
    commit('setUserAssignedLocationsIsLoading', true)
    return repository.Location.GetAssignedUserLocations(payload)
      .then((res) => {
        commit('setLocationsDeviceList', res.data.locations)
      })
      .catch(() => {
        commit('setLocationsDeviceList', [])
      })
      .finally(() => {
        commit('setUserAssignedLocationsIsLoading', false)
      })
  },
  async createCustomerAccessKey({ commit }, payload) {
    commit('setNewKeyLoading', true)
    commit('setSaveTokenErrorMessage', '')
    return repository.Token.setToken(payload)
      .then((res) => {
        commit('setNewKeyString', res.data)
      })
      .catch((err) => {
        commit('setSaveTokenErrorMessage', err.response.data.messageLocalizable)
      })
      .finally(() => {
        commit('setNewKeyLoading', false)
      })
  },
  async deleteTokenById({ commit }, payload) {
    commit('setTokenStatusIsLoading', true)
    return repository.Token.deleteToken(payload)
      .then(() => {
        commit('setUpdateTokenSuccess', true)
      })
      .catch((err) => {
        commit('setUpdateTokenSuccess', false)
        commit(
          'setUpdateTokenErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setTokenStatusIsLoading', false)
      })
  },
  async updateTokenStatus({ commit }, payload) {
    commit('setTokenStatusIsLoading', true)
    return repository.Token.updateToken(payload)
      .then(() => {
        commit('setUpdateTokenSuccess', true)
      })
      .catch((err) => {
        commit('setUpdateTokenSuccess', false)
        commit(
          'setUpdateTokenErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setTokenStatusIsLoading', false)
      })
  },
  async updateTokenById({ commit }, payload) {
    commit('setNewKeyLoading', true)
    commit('setSaveTokenErrorMessage', '')
    return repository.Token.updateTokenById(payload)
      .then(() => {
        commit('setUpdateTokenSuccess', true)
      })
      .catch((err) => {
        commit('setUpdateTokenSuccess', false)
        commit('setSaveTokenErrorMessage', err.response.data.messageLocalizable)
      })
      .finally(() => {
        commit('setNewKeyLoading', false)
      })
  },
  clearUpdateTokenErrorMessage({ commit }) {
    commit('setUpdateTokenSuccess', true)
    commit('setUpdateTokenErrorMessage', '')
  },
  resetNewKey({ commit }) {
    commit('setNewKeyString', '')
  },
}

const AccessTokens: Module<AccessTokensState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default AccessTokens

interface AccessTokensState {
  accessKeysIsLoading: boolean
  accessKeys: AccessKey[]
  locationsDeviceList: any[]
  newKeyString: string
  newKeyLoading: boolean
  updateTokenStatusIsLoading: boolean
  userAssignedLocationsIsLoading: boolean
  updateTokenErrorMessage: string
  updateTokenSuccess: boolean
  hasTokenAccess: boolean
  saveTokenErrorMessage: string
}

interface AccessKey {
  id: number
}
