import repository from '@/repository'

const getDefaultState = () => {
  return {
    getOnboardingMessageIsLoading: false,
    setOnboardingMessageViewedIsLoading: false,
    setOnboardingMessageViewedError: '',
    onboardingMessagesError: '',
    setOnboardingMessageViewedSuccess: false,
    onboardingMessages: {
      messageGroupName: '',
      content: [
        {
          displayTag: '',
          messageText: '',
        },
      ],
    },
  }
}

const state = getDefaultState()

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setGetOnboardingMessageIsLoading(state, value) {
    state.getOnboardingMessageIsLoading = value
  },
  setSetOnboardingMessageViewedIsLoading(state, value) {
    state.setOnboardingMessageViewedIsLoading = value
  },
  setOnboardingMessages(state, value) {
    state.onboardingMessages = value
  },
  setSetOnboardingMessageViewedError(state, value) {
    state.setOnboardingMessageViewedError = value
  },
  setOnboardingMessagesError(state, value) {
    state.onboardingMessagesError = value
  },
  setSetOnboardingMessageViewedSuccess(state, value) {
    state.setOnboardingMessageViewedSuccess = value
  },
}

const actions = {
  getOnboardingMessages({ commit }, payload) {
    commit('setGetOnboardingMessageIsLoading', true)
    repository.OnboardingMessages.GetOnboardingMessageById(payload)
      .then((res) => {
        commit('setOnboardingMessages', res.data)
      })
      .catch((err) => {
        commit(
          'setOnboardingMessagesError',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setGetOnboardingMessageIsLoading', false)
      })
  },
  SetOnboardingMessageViewed({ commit }, payload) {
    commit('setSetOnboardingMessageViewedIsLoading', true)
    repository.OnboardingMessages.SetOnboardingMessageViewed(payload)
      .then((res) => {
        commit('setSetOnboardingMessageViewedSuccess', res.data)
      })
      .catch((err) => {
        commit(
          'setSetOnboardingMessageViewedError',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setSetOnboardingMessageViewedIsLoading', false)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
