import repository from '@/repository'

const getDefaultState = () => {
  return {
    dealerList: [],
    dealerListIsLoading: false,
    addDealerIsLoading: false,
    addDealerError: false,
    addDealerErrorMessage: '',
    newDealerId: 0,
    updateDealerIsLoading: false,
    updateDealerError: false,
    updateDealerErrorMessage: '',
    billingTypes: [],
    discountTypes: [],
    deleteDealerIsLoading: false,
    deleteDealerSuccess: true,
    deleteDealerErrorMessage: '',
    downloadReportIsLoading: false,
  }
}

const state = getDefaultState()

const getters = {
  currentDealer: (state) => (dealerId) => {
    return state.dealerList.find((dealer) => {
      return dealer.dealerId === dealerId
    })
  },
  dealers: (state) => {
    return state.dealerList
  },
}

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setDealerList(state, value) {
    state.dealerList = value
  },
  setDealerListIsLoading(state, value) {
    state.dealerListIsLoading = value
  },
  setAddDealerIsLoading(state, value) {
    state.addDealerIsLoading = value
  },
  setAddDealerError(state, value) {
    state.addDealerError = value
  },
  setAddDealerErrorMessage(state, value) {
    state.addDealerErrorMessage = value
  },
  setNewDealerId(state, value) {
    state.newDealerId = value
  },
  setUpdateDealerIsLoading(state, value) {
    state.updateDealerIsLoading = value
  },
  setUpdateDealerError(state, value) {
    state.updateDealerError = value
  },
  setUpdateDealerErrorMessage(state, value) {
    state.updateDealerErrorMessage = value
  },
  setUpdatedDealer(state, updatedDealerData) {
    let isUpdatedDealer = (dealer) =>
      dealer.dealerId == updatedDealerData.dealerId
    let selectedDealerIndex = state.dealerList.findIndex(isUpdatedDealer)
    state.dealerList[selectedDealerIndex].dealerName =
      updatedDealerData.dealerName
    state.dealerList[selectedDealerIndex].address = updatedDealerData.address
    state.dealerList[selectedDealerIndex].address2 = updatedDealerData.address2
    state.dealerList[selectedDealerIndex].city = updatedDealerData.city
    state.dealerList[selectedDealerIndex].stateCode =
      updatedDealerData.stateCode
    state.dealerList[selectedDealerIndex].zip = updatedDealerData.zip
    state.dealerList[selectedDealerIndex].phone = updatedDealerData.phone
  },
  updateBillingTypeList(state, value) {
    let dealerId = value.dealerId
    state.dealerList.forEach((item) => {
      if (item.dealerId === dealerId) {
        item.billingType = value.billingType
        item.billingTypeUpdateTime = value.billingTypeUpdateTime
        item.discountType = value.discountType
        item.isImmixEnabled = value.isImmixEnabled
      }
    })
  },
  setBillingTypes(state, value) {
    state.billingTypes = value
  },
  setDiscountTypes(state, value) {
    state.discountTypes = value
  },
  setDeleteDealerIsLoading(state, value) {
    state.deleteDealerIsLoading = value
  },
  setDeleteDealerSuccess(state, value) {
    state.deleteDealerSuccess = value
  },
  setDeleteDealerErrorMessage(state, value) {
    state.deleteDealerErrorMessage = value
  },
  deleteDealer(state, value) {
    state.dealerList = state.dealerList.filter((dealer) => {
      return dealer.dealerId != value.dealerId
    })
  },
  setDownLoadReportIsLoading(state, value) {
    state.downloadReportIsLoading = value
  },
}

const actions = {
  getDealerList({ commit }, payload) {
    commit('setDealerListIsLoading', true)
    return repository.Dealer.GetDealers(payload)
      .then((res) => {
        commit('setDealerList', res.data.items)
      })
      .catch(() => {
        //error message here
      })
      .finally(() => {
        commit('setDealerListIsLoading', false)
      })
  },

  downloadReport({ commit }, payload) {
    commit('setDownLoadReportIsLoading', true)
    return repository.InternalAdmin.DownloadReport(payload.config)
      .then((res) => {
        return res
      })
      .catch(() => {})
      .finally(() => {
        commit('setDownLoadReportIsLoading', false)
      })
  },

  addDealer({ commit }, payload) {
    commit('setAddDealerIsLoading', true)
    return repository.Dealer.Add(payload)
      .then((res) => {
        commit('setNewDealerId', res.data.dealerId)
        commit('setAddDealerError', false)
      })
      .catch((err) => {
        commit('setAddDealerError', true)
        commit('setAddDealerErrorMessage', err.response.data.messageLocalizable)
      })
      .finally(() => {
        commit('setAddDealerIsLoading', false)
      })
  },
  updateDealer({ commit }, payload) {
    commit('setUpdateDealerIsLoading', true)
    return repository.Dealer.Update(payload)
      .then((res) => {
        commit('setUpdatedDealer', res.data)
        commit('setUpdateDealerError', false)
      })
      .catch((err) => {
        commit('setUpdateDealerError', true)
        commit(
          'setUpdateDealerErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdateDealerIsLoading', false)
      })
  },
  updateBillingType({ commit }, payload) {
    commit('setUpdateDealerIsLoading', true)
    return repository.Dealer.UpdateBillingType(payload)
      .then((res) => {
        commit('updateBillingTypeList', res.data)
        commit('setUpdateDealerError', false)
        commit('setUpdateDealerErrorMessage', '')
      })
      .catch((err) => {
        commit('setUpdateDealerError', true)
        commit(
          'setUpdateDealerErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdateDealerIsLoading', false)
      })
  },
  getBillingTypes({ commit }) {
    return repository.Dealer.GetBillingTypes()
      .then((res) => {
        commit('setBillingTypes', res.data.items)
      })
      .catch(() => {})
      .finally(() => {})
  },
  getDiscountTypes({ commit }) {
    return repository.Dealer.GetDiscountTypes()
      .then((res) => {
        commit('setDiscountTypes', res.data.items)
      })
      .catch(() => {})
      .finally(() => {})
  },
  async deleteDealer({ commit }, payload) {
    commit('setDeleteDealerIsLoading', true)
    return repository.InternalAdmin.DeleteDealer(payload)
      .then(() => {
        commit('setDeleteDealerSuccess', true)
        commit('deleteDealer', payload)
      })
      .catch((err) => {
        commit(
          'setDeleteDealerErrorMessage',
          err.response.data.messageLocalizable
        )
        commit('setDeleteDealerSuccess', false)
      })
      .finally(() => {
        commit('setDeleteDealerIsLoading', false)
      })
  },
  callDeleteDealerErrorMessage({ commit }, payload) {
    commit('setDeleteDealerErrorMessage', payload || '')
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
