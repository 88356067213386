
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { isMobile } from 'mobile-device-detect'

export default defineComponent({
  name: 'TokenAuthErrorDialog',
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapState('Security', ['isLoggedIn', 'isTokenAuthenticated']),
    externalCloudCases() {
      return this.isTokenAuthenticated
    },
    isMobile() {
      return isMobile
    },
  },
  methods: {
    showDialog() {
      if (this.externalCloudCases && !this.isLoggedIn) {
        this.dialog = true
      }
    },
    goToExternalUrl() {
      const externalUrl = localStorage.getItem('externalUrl')
      if (externalUrl) {
        window.location.href = externalUrl
      }
    },
  },
  watch: {
    isLoggedIn() {
      this.showDialog()
    },
  },
  mounted() {
    this.showDialog()
  },
})
