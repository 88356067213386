import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'
import i18n from '../../i18n'

const getDefaultState = (): AlertsStore => {
  return {
    alertsList: [],
    alertsListIsLoading: false,
    analyticsTypes: [],
    analyticsTypesIsLoading: false,
    createAlertIsLoading: false,
    newAlert: {},
    objectTypes: [],
  }
}

const state = getDefaultState()

const mutations: MutationTree<AlertsStore> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setAlertsList(state, value) {
    state.alertsList = value
  },
  setAlertsListIsLoading(state, value) {
    state.alertsListIsLoading = value
  },
  setAnalyticsTypes(state, value) {
    state.analyticsTypes = value
  },
  setAnalyticsTypesIsLoading(state, value) {
    state.analyticsTypesIsLoading = value
  },
  setCreateAlertIsLoading(state, value) {
    state.createAlertIsLoading = value
  },
  setNewAlert(state, value) {
    state.newAlert = value
  },
  setObjectTypes(state, value) {
    state.objectTypes = value
  },
}

const actions: ActionTree<AlertsStore, RootState> = {
  async getAlertsList({ commit }) {
    commit('setAlertsListIsLoading', true)
    // wire in api here
    const list = [
      { name: 'alert 1', id: 0 },
      { name: 'alert 2', id: 1 },
    ]
    commit('setAlertsList', list)
    setTimeout(() => {
      commit('setAlertsListIsLoading', false)
    }, 1000)
  },
  async getAnalyticsTypes({ commit }) {
    commit('setAnalyticsTypesIsLoading', true)
    // wire in api here
    const list = [
      { name: 'Object Counting', id: 0 },
      { name: 'Dwell Time', id: 1 },
    ]
    commit('setAnalyticsTypes', list)
    setTimeout(() => {
      commit('setAnalyticsTypesIsLoading', false)
    }, 1000)
  },
  async createAlertStepZero({ commit }, payload) {
    commit('setCreateAlertIsLoading', true)
    payload.alertId = 1
    commit('setNewAlert', payload)
    setTimeout(() => {
      commit('setCreateAlertIsLoading', false)
    }, 1000)
  },
  async getObjectTypes({ commit }) {
    const objectTypes = [{ name: i18n.t('admin.alerts.Person'), id: 0 }]
    commit('setObjectTypes', objectTypes)
  },
}

const Alerts: Module<AlertsStore, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
}
export default Alerts

interface AlertsStore {
  alertsList: any[]
  alertsListIsLoading: boolean
  analyticsTypes: any[]
  analyticsTypesIsLoading: boolean
  createAlertIsLoading: boolean
  newAlert: any
  objectTypes: any[]
}
