
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import passwordStrengthRules from '../../mixins/passwordStrengthRules'
import { CREATE_ACCOUNT } from '@/constants/mixpanelEvents'

export default defineComponent({
  name: 'SignUpForm',
  mixins: [passwordStrengthRules],
  props: {
    emailAddress: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      signUpForm: '',
      password: '',
      confirmPassword: '',
      localEmailAddress: this.emailAddress,
    }
  },
  watch: {
    emailAddress() {
      this.localEmailAddress = this.emailAddress
    },
  },
  computed: {
    ...mapState('SignUp', [
      'signUpIsLoading',
      'signUpError',
      'signUpErrorMessage',
    ]),
    signUpErrorMessageText() {
      return this.$t(this.signUpErrorMessage)
    },
    signUpActive() {
      if (
        this.password == this.confirmPassword &&
        this.confirmPassword != '' &&
        this.valid_password
      ) {
        return true
      } else {
        return false
      }
    },
    emailLabel() {
      return this.$t('global.email')
    },
    passwordLabel() {
      return this.$t('global.password')
    },
    confirmPasswordLabel() {
      return this.$t('global.confirmPassword')
    },
  },
  methods: {
    ...mapActions('SignUp', ['callSignUp']),
    clickSignUpBtn() {
      if (this.signUpActive) {
        let payload = {
          VerificationToken: this.$router.currentRoute.query.id,
          NewPassword: btoa(this.password),
        }
        this.callSignUp(payload).then(() => {
          if (this.signUpError) {
            this.$mixpanelTrack(CREATE_ACCOUNT, {
              'Account Created': false,
            })
          }
        })
      }
    },
    checkLocalPassword() {
      this.checkPassword()
    },
  },
  created() {
    // quick check to ensure user has reset password token
    if (this.$router.currentRoute.query.id == null) {
      this.$router.push('/login')
    }
  },
})
