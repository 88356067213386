
import { defineComponent } from 'vue'
import * as Colors from '@/constants/colors'
import axios from 'axios'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  name: 'DownloadCaseBanner',
  data() {
    return {
      loading: true,
      isFirstZip: true,
      localCancel: false,
    }
  },
  computed: {
    ...mapState('WebSocket', [
      'downloadReady',
      'downloadPayload',
      'downloadError',
    ]),
    ...mapState('Case', [
      'caseDownloading',
      'caseDownloadingID',
      'caseDownloadReady',
    ]),
    greenColor() {
      return Colors.CSS_HEX_GREEN
    },
  },
  methods: {
    ...mapActions('Case', ['clickCancelDownload']),
    ...mapActions('ToastMessage', ['callFailureToast', 'callSuccessToast']),
    ...mapActions('WebSocket', ['resetDownload']),
    cancelDownload() {
      this.localCancel = true
      this.clickCancelDownload()
      this.resetDownload()
    },
    generateDownload(url) {
      axios({
        method: 'get',
        url: url,
        responseType: 'arraybuffer',
        timeout: 10000,
      })
        .then((res) => {
          if (!this.localCancel) this.forceDownload(res)
        })
        .catch((err) => {
          if (!this.localCancel) {
            if (err.code === 'ECONNABORTED') {
              this.callSuccessToast({
                toastMessage: this.$t('toastMessage.clickDownloadLink'),
                toastDownloadLink: url,
              })
            } else {
              setTimeout(() => {
                this.callFailureToast({
                  toastMessage: this.$t('toastMessage.caseDownloadFailed'),
                })
              }, 1500)
            }
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.cancelDownload()
            this.isFirstZip = true
            this.localCancel = false
            this.resetDownload()
          }, 750)
        })
    },
    forceDownload(zipFile) {
      let blob = new Blob([zipFile.data], { type: 'application/zip' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'CaseID_' + this.caseDownloadingID + '.zip'
      link.click()
    },
  },
  watch: {
    caseDownloading() {
      if (this.caseDownloading) this.localCancel = false
    },
    downloadReady() {
      if (this.downloadReady) {
        this.isFirstZip = false
        this.generateDownload(this.downloadPayload)
      }
    },
    downloadError() {
      if (this.downloadError) {
        this.callFailureToast({
          toastMessage: this.$t('toastMessage.caseDownloadFailed'),
        })
        setTimeout(() => {
          this.cancelDownload()
        }, 1000)
        this.isFirstZip = true
        this.localCancel = false
      }
    },
  },
})
