
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'ExternalBanner',
  computed: {
    ...mapState('Security', ['isTokenAuthenticated', 'externalSystemName']),
    externalCloudCases() {
      return this.isTokenAuthenticated
    },
    backToExternalTitle() {
      return this.externalSystemName
        ? `${this.$t('dealer.backTo')} ${this.externalSystemName}`
        : this.$t('dealer.backToExternalPortal')
    },
  },
  methods: {
    backToExternal() {
      const externalUrl = localStorage.getItem('externalUrl')
      if (externalUrl) {
        window.location.href = externalUrl
      }
    },
  },
})
